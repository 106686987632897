import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MainCard from "admin/components/MainCard";
import { trackFooterLinksEngaged } from "utils/segmentAnalytics";

export default function Footer() {
  const handleLinkClick = (link) => {
    trackFooterLinksEngaged(link);
  };

  return (
    <Box sx={{ mt: "auto" }}>
      <MainCard sx={{ mt: 4 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
          <Typography variant="caption">&copy; 2024 Impact Connect. All Rights Reserved.</Typography>
          <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
            <Link
              component="a"
              onClick={() => handleLinkClick("About Us")}
              href="https://impactconnect.notion.site/Impact-Connect-About-Us-458b456143ac460a8fcae88ed3ca5ff0"
              variant="caption"
              color="text.primary"
            >
              About Us
            </Link>
            <Link
              component="a"
              onClick={() => handleLinkClick("Privacy Policy")}
              href="https://impactconnect.notion.site/Impact-Connect-Privacy-Policy-2ed7e01814f8460882838e3f18fc0579"
              variant="caption"
              color="text.primary"
            >
              Privacy Policy
            </Link>
            <Link
              component="a"
              onClick={() => handleLinkClick("Terms")}
              href="https://impactconnect.notion.site/Impact-Connect-Terms-and-Conditions-9d77e8695fb741278af004052521beea"
              variant="caption"
              color="text.primary"
            >
              Terms of Use
            </Link>
          </Stack>
        </Stack>
      </MainCard>
    </Box>
  );
}
