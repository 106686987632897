import { Comments } from "@hyvor/hyvor-talk-react";
import { useAuth0 } from "@auth0/auth0-react";
import { routes } from "utils/routes";
import { useSelector } from "react-redux";
import { getCommentSectionSsoData } from "reduxUtils/userProfileSlice";

export default function HyvorCommentsSection({ product }) {
  const { loginWithRedirect } = useAuth0();
  const ssoData = useSelector(getCommentSectionSsoData);

  return (
    <Comments
      website-id={process.env.REACT_APP_HYVOR_COMMENTS_SECTION_ID}
      page-id={product.id}
      sso-user={ssoData?.user_data}
      sso-hash={ssoData?.user_hash}
      on={{
        "auth:login:clicked": () =>
          loginWithRedirect({
            appState: {
              returnTo: routes.product.replace(":slugProductName", product.slug_product_name),
            },
          }),
      }}
    />
  );
}
