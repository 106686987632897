import { Box, Typography, TextField, MenuItem } from "@mui/material";
import { Field } from "formik";
import { useSelector } from "react-redux";
import { selectCategoriesList, selectLocationsList } from "reduxUtils/productSlice";
import Grid from "@mui/material/Grid";
import SingleFileUpload from "admin/components/third-party/dropzone/SingleFile";
import MultiFileUpload from "admin/components/third-party/dropzone/MultiFile";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const FirstSectionProductForm = ({ touched, setFieldTouched, errors, values, setFieldValue }) => {
  const categories = useSelector(selectCategoriesList);
  const locations = useSelector(selectLocationsList);

  return (
    <Box>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant="body1">Startup Name*</Typography>
          <Field
            as={TextField}
            name="product_name"
            label=""
            fullWidth
            sx={{ mb: 2 }}
            error={touched.product_name && Boolean(errors.product_name)}
            helperText={touched.product_name && errors.product_name}
          />
          <Typography variant="body1">Startup one liner*</Typography>
          <Field
            as={TextField}
            name="startup_one_liner"
            label=""
            fullWidth
            sx={{ mb: 2 }}
            error={touched.startup_one_liner && Boolean(errors.startup_one_liner)}
            helperText={touched.startup_one_liner && errors.startup_one_liner}
          />
          <Typography variant="body1">Startup description*</Typography>
          <Field
            as={TextField}
            name="description"
            label=""
            fullWidth
            sx={{ mb: 2, textarea: { overflowY: "scroll !important" } }} // fix for mui textarea ResizeObserver bug
            multiline
            rows={4}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
          <Typography variant="body1">Additional startup information</Typography>
          <Typography variant="body2">This is for you to provide addition information that might be relevant about the startup.</Typography>
          <Field
            as={TextField}
            name="additional_information"
            label=""
            fullWidth
            sx={{ mb: 2, textarea: { overflowY: "scroll !important" } }}
            multiline
            rows={4}
            error={touched.additional_information && Boolean(errors.additional_information)}
            helperText={touched.additional_information && errors.additional_information}
          />
          <Typography variant="body1">Location*</Typography>
          <Field
            as={TextField}
            name="location"
            label=""
            fullWidth
            select
            SelectProps={{
              multiple: false,
            }}
            sx={{ mb: 2 }}
            error={touched.location && Boolean(errors.location)}
            helperText={touched.location && errors.location}
            onChange={(event) => setFieldValue("location", event.target.value)}
          >
            {locations.map((location) => (
              <MenuItem key={location.code} value={location.id}>
                {location.country_name}
              </MenuItem>
            ))}
          </Field>
          <Typography variant="body1">Category</Typography>
          <Typography variant="body2">
            Select a category that best describes your product's purpose or the problem it solves. Can't find a perfect fit? No worries!
            Just tell us, and we might add a new category for you.
          </Typography>
          <Field
            as={TextField}
            name="categories"
            label=""
            fullWidth
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => selected.map((s) => categories.find((category) => category.id === s).name).join(", "),
            }}
            sx={{ mb: 2 }}
            error={touched.categories && Boolean(errors.categories)}
            helperText={touched.categories && errors.categories}
            onChange={(event) => {
              setFieldValue("categories", event.target.value);
            }}
          >
            {categories.map((category) => (
              <MenuItem key={category.name} value={category.id}>
                <Checkbox checked={values.categories.some((c) => c === category.id)} />
                <ListItemText primary={category.name} />
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Startup Logo*</Typography>
              <Typography variant="body2">Please ensure the logo is 250px by 250px.</Typography>
                <SingleFileUpload
                  sx={{ mx: "auto", maxWidth: { xs: "250px", md: "100%" } }}
                  setFieldValue={setFieldValue}
                  fieldName="product_logo"
                  file={values.product_logo}
                  error={touched.product_logo && !!errors.product_logo}
                  helperText={touched.product_logo && errors.product_logo}
                  setFieldTouched={() => setFieldTouched("product_logo", true, true)}
                />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" height="100%">
                <Typography variant="body1">Startup banner image*</Typography>
                <Typography variant="body2">You can upload up to 3 banner images. Recommended size 1280px by 720px</Typography>
                <MultiFileUpload
                  sx={{ mt: "auto" }}
                  setFieldValue={setFieldValue}
                  showList
                  maxFiles={3}
                  type="default"
                  fieldName="product_media_files"
                  files={values.product_media_files}
                  error={touched.product_media_files && !!errors.product_media_files}
                  helperText={touched.product_media_files && errors.product_media_files}
                  setFieldTouched={() => setFieldTouched("product_media_files", true, true)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstSectionProductForm;
