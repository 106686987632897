import { GlobalStyles } from "@mui/material";

export const mainStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        fontSize: "62.5%",
        height: "100%",
      },
      body: {
        backgroundColor: theme.tertiary.bgLight,
      },
      ".image-gallery-slide img": {
        padding: "1px",
      },
    })}
  />
);
