import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import { useMediaQuery } from "@mui/material";

export default function EmbeddedCalendarImpactCoffee() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <div
        className="calendly-inline-widget"
        data-url={process.env.REACT_APP_CALENDLY_IMPACT_COFFEE_URL}
        data-resize="true"
        style={{
          minWidth: isXs ? "200px" : "500px",
        }}
      ></div>
    </Card>
  );
}
