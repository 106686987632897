import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StarIcon from "@mui/icons-material/Star";
import Chip from "@mui/material/Chip";
import { createRedirectUrl } from "utils/helperFunc";
import { trackExternalArticleClicked } from "utils/segmentAnalytics";

export const Article = ({ article, isSponsored, productName }) => {

  const url = createRedirectUrl(article.url, productName);

  const handleClick = () => {
    trackExternalArticleClicked(isSponsored ? "Sponsored" : "Related", productName, article.url)
  }

  return (
    <a href={url} style={{ textDecoration: "none", color: "inherit" }}>
      <Box href={url} onClick={handleClick}>
        {isSponsored && <img src={article.image} alt="" style={{ width: "100%" }} />}
        {/* <Box display="flex" mt={1}>
              <Typography variant="verySmallText">Category</Typography>
              <Typography variant="verySmallText" sx={{ ml: 1 }}>
                5 min read
              </Typography>
            </Box> */}
        <Box p={3}>
          {isSponsored && (
            <Chip
              sx={{ mb: 2, borderRadius: 1}}
              
              label="Sponsored Article"
              fontSize="small"
              variant="outlined"
              icon={<StarIcon style={{ color: "#FFEC2E" }} />}
            />
          )}
          <Typography variant="h5" mb={2}>
            {article.title}
          </Typography>
          <Typography variant="smallText">{article.description}</Typography>

          <Box display="flex" mt={2}>
            <Typography variant="verySmallText">Read more </Typography>
            <NavigateNextIcon fontSize="small" />
          </Box>
        </Box>
      </Box>
    </a>
  );
};
