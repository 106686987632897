import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "utils/AuthAxios";

export const updateUserProfile = createAsyncThunk(
  "userProfile/updateUserProfile",
  async (profileData) => {
    return AuthAxios
      .put("user-profile/", profileData)
      .then((response) => response.data);
  }
);

export const retrieveUserProfile = createAsyncThunk(
  "userProfile/retrieveUserProfile",
  async () => {
    return AuthAxios.get("user-profile/").then((response) => response.data);
  }
);

export const retrieveUserHash = createAsyncThunk(
  "userProfile/retrieveUserHash",
  async () => {
    return AuthAxios.get("user-profile/user-hash/").then((response) => response.data);
  }
);

export const UserProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    data: [],
    commentSectionSsoData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveUserProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(retrieveUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(retrieveUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUserProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Comment Section Hash ------------------------------------
      .addCase(retrieveUserHash.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(retrieveUserHash.fulfilled, (state, action) => {
        state.loading = false;
        state.commentSectionSsoData = action.payload;
      })
      .addCase(retrieveUserHash.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});



export const getUserProfile = (state) => state.userProfile.data;
export const getLoading = (state) => state.userProfile.loading;
export const getError = (state) => state.userProfile.error;
export const getCommentSectionSsoData = (state) => state.userProfile.commentSectionSsoData;

export default UserProfileSlice.reducer;
