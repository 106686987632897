import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// third-party
import { IntlProvider } from "react-intl";

// project-imports
import useConfig from "admin/hooks/useConfig";

// load locales files
const loadLocaleData = (locale) => {
  switch (locale) {
    case "en":
    default:
      return import("admin/utils/locales/en.json");
  }
};

// ==============================|| LOCALIZATION ||============================== //

export default function Locales({ children }) {
  const { i18n } = useConfig();

  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(i18n).then((d) => {
      setMessages(d.default);
    });
  }, [i18n]);

  return (
    <>
      {messages && (
        <IntlProvider locale={i18n} defaultLocale="en" messages={messages}>
          {children}
        </IntlProvider>
      )}
    </>
  );
}

Locales.propTypes = { children: PropTypes.node };
