import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export const TawkChatProvider = ({ tawkPropertyId, tawkWidgetId, children }) => {
  return (
    <>
      {children}
      <TawkMessengerReact propertyId={tawkPropertyId} widgetId={tawkWidgetId} />
    </>
  );
};
