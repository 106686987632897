import { useState } from "react";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit } from "iconsax-react";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import AddFounderDialog from "admin/sections/organisms/ProductForm/AddFounderDialog";
import { RootWrapper, DropzoneWrapper } from "admin/components/third-party/dropzone/Avatar";
import { Link } from "@mui/material";
import { ProfileAdd } from "iconsax-react";

const FounderBox = ({ values, setFieldValue, founderNumber, open, setOpen }) => {
  const [openDialog, setOpenDialog] = useState(false);

  let founderName = values[`${founderNumber}_founder_name`];
  let founderTitle = values[`${founderNumber}_founder_title`];
  let founderLogoPreview = values[`${founderNumber}_founder_logo`]
    ? values[`${founderNumber}_founder_logo`].preview
    : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

  return (
    <>
      {open && (
        <>
          <Box sx={{ mb: 3, display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "center" }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
              <RootWrapper sx={{ width: { xs: 70, sm: 100 }, height: { xs: 70, sm: 100 }, mr: 1 }}>
                <DropzoneWrapper>
                  <img
                    src={founderLogoPreview}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    alt="Founder profile"
                  />
                </DropzoneWrapper>
              </RootWrapper>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: { xs: "center", sm: "flex-start" },
                  mr: 1,
                }}
              >
                <Typography variant="h4">{founderName}</Typography>
                <Typography variant="body1">{founderTitle}</Typography>
              </Box>
            </Box>
            <Box sx={{ ml: "auto", mr: { xs: "auto", sm: "inherit" }, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Box>
                <IconButton
                  size="large"
                  sx={{
                    height: 60,
                    width: 60,
                    "&.MuiButtonBase-root svg": {
                      height: "30px",
                      width: "30px",
                    },
                  }}
                  onClick={() => setOpenDialog(true)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="large"
                  color="error"
                  sx={{
                    height: 60,
                    width: 60,
                    "&.MuiButtonBase-root svg": {
                      height: "30px",
                      width: "30px",
                    },
                  }}
                  onClick={() => {
                    setOpen(false);
                    setFieldValue(`${founderNumber}_founder_logo`, "");
                    setFieldValue(`${founderNumber}_founder_name`, "");
                    setFieldValue(`${founderNumber}_founder_title`, "");
                    setFieldValue(`${founderNumber}_founder_linkedin`, "");
                  }}
                >
                  <HighlightOffTwoToneIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <AddFounderDialog
            open={openDialog}
            setOpen={setOpenDialog}
            onSuccess={(values) => {
              setFieldValue(`${founderNumber}_founder_logo`, values.founder_logo);
              setFieldValue(`${founderNumber}_founder_name`, values.founder_name);
              setFieldValue(`${founderNumber}_founder_title`, values.founder_title);
              setFieldValue(`${founderNumber}_founder_linkedin`, values.founder_linkedin);
            }}
            initialValues={{
              founder_logo: values[`${founderNumber}_founder_logo`],
              founder_name: values[`${founderNumber}_founder_name`],
              founder_title: values[`${founderNumber}_founder_title`],
              founder_linkedin: values[`${founderNumber}_founder_linkedin`],
            }}
            isEdit={true}
          />
        </>
      )}
    </>
  );
};

const ThirdSectionProductForm = ({ touched, errors, setFieldValue, values, validateForm }) => {
  const [open, setOpen] = useState(false);

  // to refactor on founder refactor
  const [showFirstFounder, setShowFirstFounder] = useState(
    values.first_founder_name !== "" && values.first_founder_logo !== "" && values.first_founder_title !== ""
  );
  const [showSecondFounder, setShowSecondFounder] = useState(
    values.second_founder_name !== "" && values.second_founder_logo !== "" && values.second_founder_title !== ""
  );
  const [showThirdFounder, setShowThirdFounder] = useState(
    values.third_founder_name !== "" && values.third_founder_logo !== "" && values.third_founder_title !== ""
  );

  const [selectedFounder, setSelectedFounder] = useState("first");

  const handleAddFounder = () => {
    if (!showFirstFounder) {
      setSelectedFounder("first");
    } else if (!showSecondFounder) {
      setSelectedFounder("second");
    } else if (!showThirdFounder) {
      setSelectedFounder("third");
    }
    setOpen(true);
  };

  const handleEditFounder = (founderNumber) => {
    setSelectedFounder(founderNumber);
    setOpen(true);
  };

  const showFounder = (founderNumber) => {
    if (founderNumber === "first") {
      setShowFirstFounder(true);
    } else if (founderNumber === "second") {
      setShowSecondFounder(true);
    } else if (founderNumber === "third") {
      setShowThirdFounder(true);
    }
  };

  return (
    <Box sx={{ p: { xs: 0, sm: 5 } }}>
      <FounderBox
        values={values}
        setFieldValue={setFieldValue}
        founderNumber="first"
        editFounder={handleEditFounder}
        open={showFirstFounder}
        setOpen={setShowFirstFounder}
      />
      <FounderBox
        values={values}
        setFieldValue={setFieldValue}
        founderNumber="second"
        editFounder={handleEditFounder}
        open={showSecondFounder}
        setOpen={setShowSecondFounder}
      />
      <FounderBox
        values={values}
        setFieldValue={setFieldValue}
        founderNumber="third"
        editFounder={handleEditFounder}
        open={showThirdFounder}
        setOpen={setShowThirdFounder}
      />
      {(!showFirstFounder || !showSecondFounder || !showThirdFounder) && (
        <Box sx={{ mb: 3, display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
          <RootWrapper onClick={() => setOpen(true)} sx={{ width: { xs: 70, sm: 100 }, height: { xs: 70, sm: 100 }, mr: 1 }}>
            <DropzoneWrapper sx={{ "& > *": { width: 30, height: 30 } }}>
              <ProfileAdd />
            </DropzoneWrapper>
          </RootWrapper>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mr: 1 }}>
            <Link style={{ cursor: "pointer" }} variant="h5" onClick={() => handleAddFounder()}>
              Add new founder
            </Link>
          </Box>
        </Box>
      )}
      <AddFounderDialog
        open={open}
        setOpen={setOpen}
        onSuccess={(resValues) => {
          setFieldValue(`${selectedFounder}_founder_logo`, resValues.founder_logo);
          setFieldValue(`${selectedFounder}_founder_name`, resValues.founder_name);
          setFieldValue(`${selectedFounder}_founder_title`, resValues.founder_title);
          setFieldValue(`${selectedFounder}_founder_linkedin`, resValues.founder_linkedin);
          showFounder(selectedFounder);
        }}
        isEdit={false}
      />
    </Box>
  );
};

export default ThirdSectionProductForm;
