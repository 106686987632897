
const admin = '/impact-portal';
const adminAddProduct = `${admin}/add-startup`;

export const routes = {
    home: '/',
    homeCategory: '/?category=:slugCategory?',
    product: '/startup/:slugProductName',
    admin: admin,
    adminHome: `${admin}/home`,
    adminSuggestProduct: `${admin}/suggest-startup`,
    adminAddProduct: adminAddProduct,
    adminAddProductDashboard: `${adminAddProduct}/dashboard`,
    adminAddProductForm: `${adminAddProduct}/form`,
    adminEditProductForm: `${admin}/edit-startup/form/:slugProductName`,
    adminImpactCoffee: `${admin}/impact-coffee`,
    adminPitchYourStartup: `${admin}/pitch-your-startup`,
}
