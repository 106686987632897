import React from "react";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faSnapchat } from "@fortawesome/free-brands-svg-icons";
import { trackFooterLinksEngaged } from "utils/segmentAnalytics";

export const ICLinksIcons = () => {
  const handleLinkClick = (link) => {
    trackFooterLinksEngaged(link);
  };

  return (
    <>
      <IconButton color={"inherit"} href="https://www.facebook.com/profile.php?id=61561257550789" size="small" target="_blank" onClick={() => handleLinkClick("Facebook")}>
        <FacebookOutlinedIcon />
      </IconButton>
      <IconButton color={"inherit"} href="https://www.instagram.com/impactconnect.tech/" onClick={() => handleLinkClick("Instagram")} size="small" target="_blank">
        <InstagramIcon />
      </IconButton>
      <IconButton color={"inherit"} href="https://x.com/ImpactConnectX" size="small" target="_blank" onClick={() => handleLinkClick("Twitter")}>
        <XIcon />
      </IconButton>
      <IconButton color={"inherit"} href="https://www.linkedin.com/company/impact-connect-tech/?viewAsMember=true" size="small" target="_blank" onClick={() => handleLinkClick("LinkedIn")}>
        <LinkedInIcon />
      </IconButton>
      <IconButton color={"inherit"} href="https://www.tiktok.com/@impactconnectx" size="small" target="_blank" onClick={() => handleLinkClick("TikTok")}>
        <FontAwesomeIcon icon={faTiktok} />
      </IconButton>
      {/* <IconButton color={"inherit"} size="small" target="_blank">
        <FontAwesomeIcon icon={faSnapchat} />
      </IconButton> */}
    </>
  );
};
