import Breadcrumbs from "admin/components/@extended/Breadcrumbs";
import { routes } from "utils/routes";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ProductForm from "admin/sections/organisms/ProductForm/ProductForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProductBySlugName } from "reduxUtils/productSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { pageNames, trackPageViewed } from "utils/segmentAnalytics";

const newProductInitialValues = {
  product_logo: "",
  product_name: "",
  startup_one_liner: "",
  description: "",
  additional_information: "",
  categories: [],
  location: "",
  product_media_files: [],
  product_media_file_title: "",
  product_website_link: "",
  instagram_link: "",
  facebook_link: "",
  twitter_link: "",
  linkedin_link: "",
  apple_store_link: "",
  google_play_link: "",
  first_founder_logo: "",
  first_founder_name: "",
  first_founder_title: "",
  first_founder_linkedin: "",
  second_founder_logo: "",
  second_founder_name: "",
  second_founder_title: "",
  second_founder_linkedin: "",
  third_founder_logo: "",
  third_founder_name: "",
  third_founder_title: "",
  third_founder_linkedin: "",
};

function TakeInitValForProduct(product) {
  let productInitVal = {
    ...product,
    product_logo: product.product_logo ? { preview: product.product_logo, name: "product-logo", type: "image", size: "0" } : "",
    location: product.location ? product.location.id : "",
    categories: product.categories ? product.categories.map((category) => category.id) : [],
    product_media_files:
      product.product_media_files && product.product_media_files.length > 0
        ? product.product_media_files.map((file) => {
            return Object.assign(
              new File([file.media_file], file.id, {
                name: file.title,
                type: "image",
                size: "0",
              }),
              { preview: file.media_file }
            );
          })
        : [],
    first_founder_logo: product.first_founder_logo
      ? { preview: product.first_founder_logo, name: "founder-logo", type: "image", size: "0" }
      : "",
    second_founder_logo: product.second_founder_logo
      ? { preview: product.second_founder_logo, name: "founder-logo", type: "image", size: "0" }
      : "",
    third_founder_logo: product.third_founder_logo
      ? { preview: product.third_founder_logo, name: "founder-logo", type: "image", size: "0" }
      : "",
  };

  delete productInitVal.id;
  delete productInitVal.contributor;
  delete productInitVal.contributor_mail;
  delete productInitVal.creator;
  delete productInitVal.is_public;
  delete productInitVal.related_article;
  delete productInitVal.slug_product_name;
  delete productInitVal.verified_badge;
  return productInitVal;
}

export default function StartupFormView() {
  const params = useParams();
  const slugProductName = params.slugProductName;
  const product = useSelector((state) => selectProductBySlugName(state, slugProductName));
  const { user } = useAuth0();

  let formInitialValues = newProductInitialValues;
  let isEdit = false;
  if (product) {
    formInitialValues = TakeInitValForProduct(product);
    isEdit = true;
  }

  //--------------------------------------------------------breadcrumb--------------------------------------------------------
  const [step, setStep] = useState(0);
  const [breadcrumbHeading, setBreadcrumbHeading] = useState("Startup basic information");

  const initBreadcrumbLinks = [
    { title: "Startup directory"},
    { title: "Add my Startup", to: routes.adminAddProductDashboard },
    { title: breadcrumbHeading },
  ];

  const [breadcrumbLinks, setBreadcrumbLinks] = useState(initBreadcrumbLinks);

  useEffect(() => {
    let newBreadcrumbHeading = "";
    switch (step) {
      case 0:
        newBreadcrumbHeading = "Startup basic information";
        break;
      case 1:
        newBreadcrumbHeading = "Startup social media links";
        break;
      case 2:
        newBreadcrumbHeading = "Startup social team";
        break;
      default:
        newBreadcrumbHeading = "Startup basic information";
        break;
    }
    setBreadcrumbHeading(newBreadcrumbHeading);
    setBreadcrumbLinks((state) => {
      state[state.length - 1].title = newBreadcrumbHeading;
      return state;
    });
  }, [step]);
  //--------------------------------------------------------breadcrumb--------------------------------------------------------

  if (product && (product.creator !== user.sub.replace("|", ".") || product.is_public)) {
    return <Navigate to={"*"} />;
  }

  return (
    <>
      <Breadcrumbs custom heading={breadcrumbHeading} links={breadcrumbLinks} />
      <Box sx={{ maxWidth: "1300px" }}>
        <ProductForm step={step} setStep={setStep} initialValues={formInitialValues} productId={product?.id} productName={product?.product_name} isEdit={isEdit} />
      </Box>
    </>
  );
}
