import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { StyledTextField } from "components/atoms/TextField/TextField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { useSnackbar } from "notistack";
import { subscribeToMailchimp } from "utils/helperFunc";
import { Typography } from "@mui/material";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { trackFooterLinksEngaged } from "utils/segmentAnalytics";

const mailchimpUrl = process.env.REACT_APP_MAILCHIMP_URL;

export const SubscribeForm = ({variant}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleLinkClick = (link) => {
    trackFooterLinksEngaged(link);
  };

  return (
    <Box>
      <Typography variant="h5" mb={1}>
        Subscribe to discover new innovative tech Startups.
      </Typography>
      {/* <Box mb={3} display="flex" alignItems="center">
        <Typography mr={"2px"}>No spam, just good startups! We promise</Typography> 
        <EmojiEmotionsIcon fontSize="small"/>
      </Box> */}
      <Box sx={{ mb: 1 }}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => {
            subscribeToMailchimp(values.email, enqueueSnackbar, mailchimpUrl);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("Invalid email").required("Email is required"),
          })}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Box display="flex">
                <Field
                  error={errors.email ? true : false}
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  size="small"
                  sx={{
                    mr: "10px",
                    // width: "370px",
                    flexGrow: 1,
                  }}
                  as={StyledTextField}
                  customvariant={variant === "primary" ? "SecondaryFilled" : "SecondaryOutlined"}
                />
                <Button type="subscribe" variant="contained" color={variant} disabled={isSubmitting}>
                  Subscribe
                </Button>
              </Box>
              {errors.email && (
                <FormHelperText sx={{ mx: "14px", fontWeight: "bold" }} error>
                  {errors.email}
                </FormHelperText>
              )}
            </Form>
          )}
        </Formik>
      </Box>
      <Typography variant="verySmallText">
        By clicking Sign Up you're confirming that you agree with our{" "}
        <a onClick={() => handleLinkClick("Terms")} href="https://impactconnect.notion.site/Impact-Connect-Terms-and-Conditions-9d77e8695fb741278af004052521beea" style={{ textDecoration: "underline", display: "inline", color: "inherit" }} > Terms and Conditions</a>.
      </Typography>
    </Box>
  );
};
