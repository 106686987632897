import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectContentLoading, setContentLoading, selectAuthorised } from "reduxUtils/globalSlice";
import { listProduct, listUpvote } from "reduxUtils/productSlice";
import { getSponsoredArticle } from "reduxUtils/sponsoredArticleSlice";
import { listCategory, listLocation } from "reduxUtils/productSlice";
import { retrieveUserHash } from "reduxUtils/userProfileSlice";
import { LoadingPage } from "utils/LoadingPage";

export const DataProvider = ({ children }) => {
  const isContentLoading = useSelector(selectContentLoading);
  const isAuthorised = useSelector(selectAuthorised);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const promises = [];

      promises.push(dispatch(listProduct()));
      promises.push(dispatch(listCategory()));
      promises.push(dispatch(listLocation()));
      promises.push(dispatch(getSponsoredArticle()));

      if (isAuthorised) {
        promises.push(dispatch(listUpvote()));
        promises.push(dispatch(retrieveUserHash()));
      }

      await Promise.all(promises);
      dispatch(setContentLoading(false));
    };

    fetchData();
  }, []);

  return <>{isContentLoading ? <LoadingPage open={isContentLoading} /> : <>{children}</>}</>;
};
