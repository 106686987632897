import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ProductGrid } from "components/organisms/ProductGrid/ProductGrid";
import { SubscribeForm } from "components/molecules/SubscribeForm/SubscribeForm";
import { PageTitle } from "components/atoms/TitlePage/PageTitle";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useResponsivePadding } from "theme/useResponsivePadding";
import { trackPageViewed, pageNames } from "utils/segmentAnalytics";
import { useEffect } from "react";

export const MainPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const responsivePadding = useResponsivePadding();

  useEffect(() => {
    trackPageViewed(pageNames.DIRECTORY_HOME);
  }, []);

  return (
    <Box>
      <PageTitle>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Typography variant="h1" align={isMobile ? "center" : "left"}>
              Discover Innovative <br /> Tech Startups By Muslims
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={5} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box mt={4}>
              <SubscribeForm variant="primary" />
            </Box>
          </Grid>
        </Grid>
      </PageTitle>

      <Box
        sx={{
          bgcolor: "background.primary",
          color: "text.primary",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ py: 5, px: responsivePadding, width: theme.width }}>
          <ProductGrid />
        </Box>
      </Box>
    </Box>
  );
};
