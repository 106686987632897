import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TextField as FormikTextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { createProduct, updateProduct } from "reduxUtils/productSlice";
import { useSnackbar } from "notistack";
import { trackSuggestStartupCompleted, trackEditSuggestedStartupCompleted } from "utils/segmentAnalytics";

const validationSchema = Yup.object({
  product_name: Yup.string().required("Name of the Startup is required"),
  contributor_mail: Yup.string().email("Invalid email").required("Email is required"),
  product_website_link: Yup.string().url("Invalid URL").required("Product URL is required"),
  linkedin_link: Yup.string().url("Invalid URL").matches("https?://([w]+.)?linkedin.com/", "Please enter a valid LinkedIn URL"),
});

export const SuggestProductForm = ({ open, setOpen, product }) => {
  const initialValues = product
    ? {
        product_name: product.product_name,
        contributor_mail: product.contributor_mail,
        product_website_link: product.product_website_link,
        linkedin_link: product.linkedin_link,
      }
    : {
        product_name: "",
        contributor_mail: "",
        product_website_link: "",
        linkedin_link: "",
      };

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values) => {
    if (!product) {
      await dispatch(createProduct(values)).unwrap();
      trackSuggestStartupCompleted();
      enqueueSnackbar("Product has been created successfully.", { variant: "success" });
    } else {
      await dispatch(updateProduct({ data: values, id: product.id })).unwrap();
      trackEditSuggestedStartupCompleted(product.product_name);
      enqueueSnackbar("Product has been updated successfully.", { variant: "success" });
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      closeAfterTransition={false} // fix for the blocked aria-hidden bug
      PaperProps={{}}
    >
      <Box display="flex" px={3} py={5} justifyConstent="center" alignContent="center">
        <Box maxWidth={480} width={470} sx={{ mx: "auto" }}>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 16,
              top: 16,
            }}
          >
            <CloseIcon />
          </IconButton>
          {product ? (
            <>
              <Typography sx={{typography: {xs: "h2", sm: "h1"}}} align="center">
                Edit a Startup
              </Typography>
            </>
          ) : (
            <>
              <Typography sx={{typography: {xs: "h2", sm: "h1"}}} align="center">
                Suggest a Startup
              </Typography>
              <Typography mt={2} variant="h5" align="center">
                Got an amazing tech product that you're eager to share?
              </Typography>
            </>
          )}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form>
              <Box mt={2}>
                <Typography variant="body2">Startup Name*:</Typography>
                <Field
                  component={FormikTextField}
                  customvariant="SecondaryFilled"
                  name="product_name"
                  size="small"
                  placeholder="Enter Startup Name"
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <Typography variant="body2">Founders Email*:</Typography>
                <Field
                  component={FormikTextField}
                  customvariant="SecondaryFilled"
                  name="contributor_mail"
                  size="small"
                  placeholder="Enter Founders Email"
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <Typography variant="body2">Startup's Website*:</Typography>
                <Field
                  component={FormikTextField}
                  customvariant="SecondaryFilled"
                  id="product_website_link"
                  name="product_website_link"
                  size="small"
                  placeholder="https://"
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <Typography variant="body2">Startup's LinkedIn:</Typography>
                <Field
                  component={FormikTextField}
                  customvariant="SecondaryFilled"
                  id="linkedin_link"
                  name="linkedin_link"
                  size="small"
                  placeholder="https://"
                  fullWidth
                />
              </Box>
              <Typography sx={{ mt: 2 }} variant="body2">
                * Required
              </Typography>
              <Box mt={2}>
                <Button type="submit" variant="contained" fullWidth>
                  {product ? "Edit startup" : "Suggest startup"}
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Dialog>
  );
};
