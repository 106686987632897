import React from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, InstapaperShareButton } from "react-share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { trackStartupPageShared } from "utils/segmentAnalytics";
import IconButton from "@mui/material/IconButton";

export const ShareIcons = ({ product }) => {
  const handleLinkClick = (platform) => {
    trackStartupPageShared(product, platform);
  };

  return (
    <>
      <IconButton
        component={LinkedinShareButton}
        url={window.location.href}
        onClick={() => handleLinkClick("LinkedIn")}
        resetButtonStyle={false}
        color={"inherit"}
        size="small"
      >
        <LinkedInIcon />
      </IconButton>
      <IconButton
        component={TwitterShareButton}
        url={window.location.href}
        onClick={() => handleLinkClick("Twitter")}
        resetButtonStyle={false}
        color={"inherit"}
        size="small"
      >
        <XIcon />
      </IconButton>
      <IconButton
        component={FacebookShareButton}
        url={window.location.href}
        onClick={() => handleLinkClick("Facebook")}
        resetButtonStyle={false}
        color={"inherit"}
        size="small"
      >
        <FacebookOutlinedIcon />
      </IconButton>
    </>
  );
};
