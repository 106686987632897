// material-ui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// project import
import NavUser from "./NavUser";
import NavCard from './NavCard';
import Navigation from "./Navigation";
import SimpleBar from "admin/components/third-party/SimpleBar";
import { useGetMenuMaster } from 'admin/api/menu';

// ==============================|| DRAWER CONTENT ||============================== //

export default function DrawerContent() {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;

  return (
    <>
      <SimpleBar sx={{ "& .simplebar-content": { display: "flex", flexDirection: "column" } }}>
        <Navigation />
        {drawerOpen && <NavCard />}
      </SimpleBar>
      {/* <NavUser /> */}
    </>
  );
}
