import PropTypes from "prop-types";
// material-ui
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";

// project-imports
import IconButton from "admin/components/@extended/IconButton";
import { DropzopType } from "admin/config";

// utils
import getDropzoneData from "admin/utils/getDropzoneData";

// type

// assets
import { CloseCircle, Document } from "iconsax-react";

// ==============================|| MULTI UPLOAD - PREVIEW ||============================== //

export default function FilesPreview({ showList = false, files, onRemove, type, error }) {
  const hasFile = files.length > 0;
  const layoutType = type;

  return (
    <List
      disablePadding
      sx={{
        ...(hasFile && type !== DropzopType.STANDARD && { my: 3 }),
        ...(type === DropzopType.STANDARD && { width: "calc(100% - 84px)" }),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        border: 1,
        borderRadius: 1.25,
        borderStyle: "dashed",
        borderColor: "secondary.main",
        bgcolor: "inherit",
        ...(error && {
          borderColor: "error.light",
          bgcolor: "error.lighter",
        }),
      }}
    >
      {files.map((file, index) => {
        const { key, name, size, preview, type } = getDropzoneData(file, index);

        if (showList) {
          return (
            <ListItem
              key={key}
              sx={{
                p: 0,
                m: 0.5,
                width: layoutType === DropzopType.STANDARD ? 64 : 100,
                height: layoutType === DropzopType.STANDARD ? 64 : 80,
                borderRadius: 1.25,
                position: "relative",
                // display: 'inline-flex',
                verticalAlign: "text-top",
                border: "1px solid",
                borderColor: "divider",
                overflow: "hidden",
              }}
            >
              {type?.includes("image") && <img alt="preview" src={preview} style={{ width: "100%" }} />}
              {!type?.includes("image") && <Document variant="Bold" style={{ width: "100%", fontSize: "1.5rem" }} />}

              {onRemove && (
                <IconButton
                  size="small"
                  color="error"
                  shape="rounded"
                  onClick={() => onRemove(file)}
                  sx={{
                    fontSize: "0.875rem",
                    bgcolor: "background.paper",
                    p: 0,
                    width: "auto",
                    height: "auto",
                    top: 2,
                    right: 2,
                    position: "absolute",
                  }}
                >
                  <CloseCircle variant="Bold" />
                </IconButton>
              )}
            </ListItem>
          );
        }

        return (
          <ListItem
            key={key}
            sx={{
              my: 1,
              px: 2,
              py: 0.75,
              borderRadius: 0.75,
              border: " 1px solid",
              borderColor: "divider",
            }}
          >
            <Document variant="Bold" style={{ width: "30px", height: "30px", fontSize: "1.15rem", marginRight: 4 }} />

            <ListItemText
              primary={typeof file === "string" ? file : name}
              secondary={typeof file === "string" ? "" : size}
              primaryTypographyProps={{ variant: "subtitle2" }}
              secondaryTypographyProps={{ variant: "caption" }}
              sx={{ display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}
            />

            {onRemove && (
              <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                <CloseCircle variant="Bold" style={{ fontSize: "1.15rem" }} />
              </IconButton>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

FilesPreview.propTypes = { showList: PropTypes.bool, files: PropTypes.any, onRemove: PropTypes.any, type: PropTypes.any };
