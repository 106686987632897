import { AnalyticsBrowser } from "@segment/analytics-next";

const segmentAnalyticsKey = process.env.REACT_APP_SEGMENT_ANALYTICS_KEY;
const analytics = AnalyticsBrowser.load({ writeKey: segmentAnalyticsKey });

export const pageNames = {
  DIRECTORY_HOME: "Directory Home page",
  STARTUP_PAGE: "Startup Page",
  PORTAL_HOME: "Portal Homepage",
  ADD_STARTUP: "Add Startup Page",
  SUGGEST_STARTUP: "Suggest Startup Page",
  IMPACT_COFFEE: "Impact Coffee Page",
  PITCH_YOUR_STARTUP: "Pitch Your Startup Page",
};

export const identifyUser = (user) => {
  analytics.identify(user.sub.replace("|", "."), {
    email: user.email,
    name: user.name,
    username: user.nickname,
  });
};

export const trackPageViewed = (page) => {
  analytics.track("Page Viewed", {
    page: page,
  });
};

export const trackStartupDirectoryFiltered = (location, category, results) => {
  analytics.track("Startup Directory Filtered", {
    "location-filter": location,
    "category-filter": category,
    results: results,
  });
};

export const trackStartupPageEngaged = (product, action) => {
  analytics.track("Startup Page Engaged", {
    startup: product.product_name,
    country: product.location.country_name,
    action: action,
  });
};

export const trackStartupPageShared = (product, platform) => {
  analytics.track("Startup Page Shared", {
    startup: product.product_name,
    platform: platform,
  });
};

export const trackStartupDetailsPageViewed = (product, referrer) => {
  analytics.track("Startup Detail Page Viewed", {
    startup: product.product_name,
    country: product.location.country_name,
    referrer: referrer,
  });
};

export const trackViewedFounderLIProfile = (product, founderName) => {
  analytics.track("Viewed Founder LI Profile", {
    startup: product.product_name,
    country: product.location.country_name,
    founder_name: founderName,
  });
};

export const trackFooterLinksEngaged = (link) => {
  analytics.track("Footer Links Engaged", {
    link: link,
  });
};

export const trackStartupThumbs = (product, action, location) => {
  analytics.track("Startup thumbs", {
    startup: product.product_name,
    country: product.location.country_name,
    action: action,
    location: location,
  });
};

export const trackExternalArticleClicked = (articleType, product_name, articleUrl) => {
  analytics.track("External Article Clicked", {
    article_type: articleType,
    startup: product_name,
    article_url: articleUrl,
  });
};

export const trackSuggestStartupPopupInitiated = () => {
  analytics.track("Suggest Startup Popup Initiated");
};

export const trackSuggestStartupCompleted = () => {
  analytics.track("Suggest Startup Completed");
};

export const trackEditSuggestedPopupInitiated = (product_name) => {
  analytics.track("Edit Suggested Startup Popup Initiated", {
    startup: product_name,
  });
};

export const trackEditSuggestedStartupCompleted = (product_name) => {
  analytics.track("Edit Suggested Startup Completed", {
    startup: product_name,
  });
};

export const actionsOnStartup = {
  MAIN_INFO_PAGE_VIEWED: "Main Info Page Viewed",
  MAIN_INFO_PAGE_COMPLETE: "Main Info Page Complete",
  SOCIAL_MEDIA_PAGE_COMPLETE: "Social Media Page Complete",
  TEAM_PAGE_COMPLETE: "Team Page Complete",
};

export const trackAddMyStartupForm = (action, founderCount) => {
  const trackData = {
    action: action,
  };

  if (founderCount) trackData.founder_count = founderCount;

  analytics.track("Add My Startup Form", trackData);
};

export const trackEditMyStartupForm = (action, product_name, founderCount) => {
  const trackData = {
    action: action,
    startup: product_name,
  };

  if (founderCount !== undefined || founderCount !== null) {
    trackData.founder_count = founderCount;
  }

  analytics.track("Edit My Startup Form", trackData);
};

export default analytics;
