import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Button from "@mui/material/Button";
import { trackViewedFounderLIProfile } from "utils/segmentAnalytics";

const FounderBox = ({ product, name, title, logo, linkedin }) => {
  const handleLinkedInClick = () => {
    trackViewedFounderLIProfile(product, name);
    window.open(linkedin, "_blank");
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={4} display="flex">
      <Box sx={{ mr: "10px", width: "80px", height: "80px", display: "flex", justifyContent: "center", alignContent: "center" }}>
        <img src={logo} alt="" style={{ maxHeight: "80px", maxWidth: "80px" }} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Typography sx={{ fontWeight: "bold" }}>{name}</Typography>
          <Typography>{title}</Typography>
        </Box>
        {linkedin !== "" && (
          <Box>
            <Button
              onClick={handleLinkedInClick}
              sx={{ py: "1px", color: "#162958", borderColor: "#162958" }}
              variant="outlined"
              startIcon={<LinkedInIcon />}
              // color={"inherit"}
              size="small"
            >
              View My Profile
            </Button>
            {/* <IconButton href={linkedin} color={"inherit"} disableRipple={true} sx={{ p: 0 }} size="small">
              <LinkedInIcon fontSize="small" />
            </IconButton> */}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export const FoundingTeamSection = ({ product }) => {
  const isFirstFounder = product.first_founder_name !== "" && product.first_founder_title !== "" && product.first_founder_logo !== null;
  const isSecondFounder = product.second_founder_name !== "" && product.second_founder_title !== "" && product.second_founder_logo !== null;
  const isThirdFounder = product.third_founder_name !== "" && product.third_founder_title !== "" && product.third_founder_logo !== null;

  if (isFirstFounder || isSecondFounder || isThirdFounder) {
    return (
      <>
        <Typography variant="h4">Founding Team</Typography>
        <Box mt={3}>
          <Grid container spacing={3} display="flex">
            {isFirstFounder && (
              <FounderBox
                product={product}
                name={product.first_founder_name}
                title={product.first_founder_title}
                logo={product.first_founder_logo}
                linkedin={product.first_founder_linkedin}
              />
            )}
            {isSecondFounder && (
              <FounderBox
                product={product}
                name={product.second_founder_name}
                title={product.second_founder_title}
                logo={product.second_founder_logo}
                linkedin={product.second_founder_linkedin}
              />
            )}
            {isThirdFounder && (
              <FounderBox
                product={product}
                name={product.third_founder_name}
                title={product.third_founder_title}
                logo={product.third_founder_logo}
                linkedin={product.third_founder_linkedin}
              />
            )}
          </Grid>
        </Box>
      </>
    );
  }
};
