import EmbeddedFormPitchYourStartup from "admin/sections/molecules/EmbeddedFormPitchYourStartup/EmbeddedFormPitchYourStartup";
import MainCard from "admin/components/MainCard";
import Typography from "@mui/material/Typography";
import { routes } from "utils/routes";
import { Link as RouterLink } from "react-router-dom";
import { pageNames, trackPageViewed } from "utils/segmentAnalytics";
import { useEffect } from "react";

export default function PitchYourStartup() {
  useEffect(() => {
    trackPageViewed(pageNames.PITCH_YOUR_STARTUP);
  }, []);

  return (
    <MainCard
      divider={false}
      sx={{ height: "100%", maxWidth: "650px" }}
      contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
    >
      <Typography variant="h5" mb={3}>
        Turn Your Startup Dream into Reality
      </Typography>
      <Typography variant="body1" mb={3}>
        Are you a Muslim founder with a groundbreaking startup idea? We want to hear from you! Submit your pitch deck to Impact Connect and
        take the first step towards transforming your vision into an investment-ready venture.
      </Typography>
      <Typography variant="body1" mb={3}>
        By sharing your pitch deck, you'll gain valuable feedback, potential mentorship opportunities, and increase your chances of securing
        the investment through our BETA partners you need to bring your idea to life
      </Typography>
      <Typography variant="body1" mb={5}>
        We aim to get back to you with feedback within 2 weeks
      </Typography>
      <EmbeddedFormPitchYourStartup />

      <Typography variant="body1" mt={5} mb={5}>
        Don't let another day pass without taking action. Want to have a chat before sending you pitch deck? Book a{" "}
        <RouterLink to={routes.adminImpactCoffee} style={{ textDecoration: "underline", display: "inline", color: "inherit" }}>
          free 30-minute Impact Coffee
        </RouterLink>{" "}
        chat with us and see how we can help your startup.
      </Typography>
    </MainCard>
  );
}
