import HomePage from "admin/pages/home-page";
import MaintenanceError from "admin/pages/maintenance/error/404";
import ThemeCustomization from "admin/themes";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import store from "reduxUtils/store";
import MainPageHelmet from "utils/MainPageHelmet";
import { ProtectedRoute } from "utils/ProtectedRoute";
import { ScrollToTop } from "utils/ScrollToTop";
import AdminPanelProviders from "utils/providers/AdminPanelProviders";
import Auth0ApiProvider from "utils/providers/Auth0ApiProvider";
import { AuthProvider } from "utils/providers/AuthProvider";
import { DataProvider } from "utils/providers/DataProvider";
import { NotificationProvider } from "utils/providers/NotificationProvider";
import { routes } from "utils/routes";
import { MainPage } from "views/Product/MainPage";
import { ProductProfile } from "views/Product/ProductProfile";
import SuggestStartup from "admin/pages/startups-section/suggest-startup";
import ClientSideProviders from "utils/providers/ClientSideProviders";
import AddStartup from "admin/pages/startups-section/add-startup";
import StartupFormView from "admin/pages/startups-section/startup-form";
import ImpactCoffee from "admin/pages/contact-section/impact-coffee";
import PitchYourStartup from "admin/pages/contact-section/pitch-your-startup";

function App() {
  return (
    <Provider store={store}>
      <Auth0ApiProvider>
        <NotificationProvider>
          <AuthProvider>
            <DataProvider>
              <HelmetProvider>
                <MainPageHelmet />
                <BrowserRouter>
                  <ScrollToTop>
                    <Routes>
                      <Route
                        path={routes.home}
                        exact
                        element={
                          <ClientSideProviders>
                            <MainPage />
                          </ClientSideProviders>
                        }
                      />
                      <Route
                        path={routes.product}
                        exact
                        element={
                          <ClientSideProviders>
                            <ProductProfile />
                          </ClientSideProviders>
                        }
                      />
                      <Route
                        path={routes.adminHome}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <HomePage />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminHome}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <HomePage />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminSuggestProduct}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <SuggestStartup />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminAddProductDashboard}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <AddStartup />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminAddProductForm}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <StartupFormView />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminEditProductForm}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <StartupFormView />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminImpactCoffee}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <ImpactCoffee />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={routes.adminPitchYourStartup}
                        element={
                          <ProtectedRoute
                            component={() => (
                              <AdminPanelProviders>
                                <PitchYourStartup />
                              </AdminPanelProviders>
                            )}
                          />
                        }
                      />
                      <Route
                        path={"*"}
                        element={
                          <ThemeCustomization>
                            <MaintenanceError />
                          </ThemeCustomization>
                        }
                      />
                    </Routes>
                  </ScrollToTop>
                </BrowserRouter>
              </HelmetProvider>
            </DataProvider>
          </AuthProvider>
        </NotificationProvider>
      </Auth0ApiProvider>
    </Provider>
  );
}

export default App;
