import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

// project-imports
import Search from "./Search";
import Message from "./Message";
import Profile from "./Profile";
import Notification from "./Notification";
import MobileSection from "./MobileSection";
import FullScreen from "./FullScreen";
import BackToDirectory from "./BackToDirectory";

import { MenuOrientation } from "admin/config";
import useConfig from "admin/hooks/useConfig";
import DrawerHeader from "admin/layout/Dashboard/Drawer/DrawerHeader";


// ==============================|| HEADER - CONTENT ||============================== //

export default function HeaderContent() {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {/* {!downLG && <Search />} */}
      {/* {downLG && <Box sx={{ width: "100%", ml: 1 }} />} */}
      <Box sx={{ width: "100%", ml: 1 }} />

      {/* <FullScreen /> */}
      {/* <Message /> */}
      {/* <Notification /> */}
      <BackToDirectory />
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
}
