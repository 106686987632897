import { useCallback, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";

// import Button from "@mui/material/Button";

// project import
import IconButton from "admin/components/@extended/IconButton";
import Button from "admin/components/@extended/Button";
import { ThemeMode } from "admin/config";

// assets
import { Back } from "iconsax-react";

import { useNavigate, Link } from "react-router-dom";
import { routes } from "utils/routes";

// ==============================|| HEADER CONTENT - FULLSCREEN ||============================== //

export default function BackToDirectory() {
  const theme = useTheme();
  const navigate = useNavigate();

  // const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? "background.paper" : "secondary.200";
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? "background.default" : "secondary.100";
  const upSM = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {upSM ? (
        <Button
          color="secondary"
          variant="light"
          onClick={() => navigate(routes.home)}
          size="large"
          sx={{ color: "secondary.main", bgcolor: iconBackColor, p: 1 }}
          startIcon={<Back variant="Bulk" />}
        >
          Back to Startup Directory
        </Button>
      ) : (
        <Tooltip title="Back to Startup Directory">
          <IconButton
            color="secondary"
            size="large"
            sx={{ color: "secondary.main", bgcolor: iconBackColor, p: 1 }}
            onClick={() => navigate(routes.home)}
          >
            <Back variant="Bulk" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
