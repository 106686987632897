import { useAuth0 } from "@auth0/auth0-react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { SvgIcon, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { LinksIcons } from "components/molecules/LinksIcons/LinksIcons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { ReactComponent as AppStoreIcon } from "images/app-store.svg";
import { ReactComponent as GooglePlayIcon } from "images/google-play.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthorised } from "reduxUtils/globalSlice";
import { createUpvote, deleteUpvote, selectProductLoading } from "reduxUtils/productSlice";
import { createRedirectUrl } from "utils/helperFunc";
import { routes } from "utils/routes";
import { trackStartupPageEngaged } from "utils/segmentAnalytics";
import Badge from "@mui/material/Badge";
import ICBadges from "components/molecules/ICBadges/ICBadges";
import { trackStartupThumbs } from "utils/segmentAnalytics";

export const HeaderProductSegment = ({ product, upvote }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const isLoading = useSelector(selectProductLoading);
  const isAuthorised = useSelector(selectAuthorised);
  const auth = useAuth0();

  const handleUpvote = async () => {
    if (product.is_public) {
      if (isAuthorised) {
        if (upvote) {
          await dispatch(deleteUpvote(upvote.id)).unwrap();
          trackStartupPageEngaged(product, "Unlike");
          trackStartupThumbs(product, "Unlike", "Product Page");
        } else {
          await dispatch(createUpvote({ product: product.id })).unwrap();
          trackStartupPageEngaged(product, "Like");
          trackStartupThumbs(product, "Like", "Product Page");
        }
      } else {
        auth.loginWithRedirect({
          appState: {
            returnTo: routes.product.replace(":slugProductName", product.slug_product_name),
            likeProduct: product.id,
          },
        });
      }
    }
  };

  const handleLinkClick = (action) => {
    trackStartupPageEngaged(product, action);
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Grid container>
        <Grid item xs={12} lg={8} display="flex" mb={2}>
          {!isTablet ? (
            <>
              <Box sx={{ mr: "20px", height: "180px", width: "180px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  badgeContent={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "white",
                      }}
                    >
                      <ICBadges product={product} />
                    </Box>
                  }
                >
                  <img src={product.product_logo} alt="" style={{ maxHeight: "180px", maxWidth: "180px" }} />{" "}
                </Badge>
              </Box>

              <Box sx={{ mr: "20px", display: "flex", flexDirection: "column" }}>
                <Box>
                  <Typography variant="h1">
                    {product.product_name} {getUnicodeFlagIcon(product.location.code)}
                  </Typography>
                  <Typography variant="h5">{product.startup_one_liner}</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <LinksIcons product={product} />
                </Box>
              </Box>
            </>
          ) : (
            <Box sx={{ mr: "20px", display: "flex", flexDirection: "column", mb: 2 }}>
              <Box
                display="flex"
                mb={2}
                sx={{ flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "center" : "flex-start" }}
              >
                <Box
                  sx={{ mr: "20px", height: "180px", width: "180px", display: "flex", justifyContent: "center", alignContent: "center" }}
                >
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    badgeContent={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          backgroundColor: "white",
                        }}
                      >
                        <ICBadges product={product} />
                      </Box>
                    }
                  >
                    <img src={product.product_logo} alt="" style={{ maxHeight: "180px", maxWidth: "180px" }} />
                  </Badge>
                </Box>
                <Typography variant="h1">
                  {product.product_name} {getUnicodeFlagIcon(product.location.code)}
                </Typography>
              </Box>
              <Typography variant="h5">{product.startup_one_liner}</Typography>
              <Box>
                <LinksIcons product={product} />
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={4} display="flex" flexDirection="column" justifyContent="center">
          <Box>
            <Grid container spacing={1} display="flex" sx={{ justifyContent: !isTablet ? "flex-end" : "flex-start" }}>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  size="large"
                  sx={{
                    height: "60px",
                    "&.Mui-disabled": {
                      // background: upvote ? theme.palette.approved.main : "inherit",
                      color: upvote ? theme.palette.approved.main : "primary",
                      borderColor: upvote ? theme.palette.approved.main : "primary",
                    },
                  }}
                  color={upvote ? "approved" : "primary"}
                  onClick={handleUpvote}
                >
                  {upvote ? <ThumbUpIcon sx={{ mr: "20px" }} /> : <ThumbUpOutlinedIcon sx={{ mr: "20px" }} />}
                  <Typography variant="largeText">Like</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleLinkClick("Website")}
                  href={createRedirectUrl(product.product_website_link, product.product_name)}
                  target="_blank"
                  variant="outlined"
                  size="large"
                  // sx={{ width: "215px" }}
                  sx={{ height: "60px" }}
                >
                  <Typography variant="largeText">Visit Website</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container spacing={1} display="flex" sx={{ justifyContent: !isTablet ? "flex-end" : "flex-start" }}>
              {product.apple_store_link && (
                <Grid item>
                  <Button
                    onClick={() => handleLinkClick("AppleAppStore")}
                    href={createRedirectUrl(product.apple_store_link, product.product_name)}
                    target="_blank"
                    variant="outlined"
                    size="large"
                    sx={{ height: "60px" }}
                  >
                    <SvgIcon viewBox="0 0 27 34">
                      <AppStoreIcon />
                    </SvgIcon>
                    <Box ml={1}>
                      <Typography sx={{ fontSize: "10px", lineHeight: "10px" }}>Download on the</Typography>
                      <Typography sx={{ fontSize: "16px", lineHeight: "16px" }}>App Store</Typography>
                    </Box>
                  </Button>
                </Grid>
              )}
              <Grid item>
                {product.google_play_link && (
                  <Button
                    onClick={() => handleLinkClick("GooglePlayStore")}
                    href={createRedirectUrl(product.google_play_link, product.product_name)}
                    target="_blank"
                    variant="outlined"
                    size="large"
                    sx={{ height: "60px" }}
                  >
                    <SvgIcon viewBox="0 0 28 32">
                      <GooglePlayIcon />
                    </SvgIcon>
                    <Box ml={1}>
                      <Typography sx={{ fontSize: "10px", lineHeight: "10px" }}>GET IT ON</Typography>
                      <Typography sx={{ fontSize: "16px", lineHeight: "16px" }}>Google Play</Typography>
                    </Box>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
