import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/material/styles";

export const LoadingPage = ({ open, sx }) => {
  const theme = useTheme();

  return (
    <Backdrop
      sx={{
        backgroundColor: "#E4E9F7", // issue with the theme provider - fixing the bug, not sovling the problem
        ...sx,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CircularProgress sx={
        {
          color: "#162958",
        }
      }/>
    </Backdrop>
  );
};
