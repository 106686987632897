import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import MainCard from "admin/components/MainCard";
import { SuggestProductForm } from "admin/sections/molecules/SuggestProductForm/SuggestProductForm";
import { routes } from "utils/routes";
import { Edit, ImportSquare } from "iconsax-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { trackEditSuggestedPopupInitiated } from "utils/segmentAnalytics";

export default function ProductBox({ product, isLive }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const hendleEdit = () => {
    trackEditSuggestedPopupInitiated(product.product_name);
    setOpen(true);
  }

  return (
    <>
      <SuggestProductForm open={open} setOpen={setOpen} product={product} />
      <MainCard divider={false} sx={{ height: "100%" }} contentSX={{ height: "100%" }}>
        <Box sx={{ mt: "10px" }}>
          <Typography
            variant="h4"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-all",
            }}
          >
            {product.product_name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" sx={{ my: "10px" }}>
          <Box>
            <Link
              component="button"
              //   underline="none"
              variant="body1"
              color="text.primary"
              //   onClick={(e) => setCategory(e.target.value)}
              display="flex"
              onClick={
                isLive
                  ? () => {
                      navigate(routes.product.replace(":slugProductName", product.slug_product_name), { state: { referrer: "Direct" } });
                    }
                  : () => hendleEdit()
              }
            >
              {isLive ? <ImportSquare /> : <Edit />}
              <Typography ml={1}>{isLive ? "Visit Startup" : "Edit Startup"}</Typography>
            </Link>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Chip variant="combined" color={isLive ? "success" : "warning"} size="small" label={isLive ? "Live" : "Pending"} />
          </Box>
        </Box>
      </MainCard>
    </>
  );
}
