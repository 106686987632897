import React from 'react';
import DashboardLayout from "admin/layout/Dashboard";
import Locales from "admin/components/Locales";
import ThemeCustomization from "admin/themes";
import { useEffect } from 'react';

const AdminPanelProviders = ({ children }) => {

  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
  }, []);

  return (
    <Locales>
      <ThemeCustomization>
        <DashboardLayout>
          {children}
        </DashboardLayout>
      </ThemeCustomization>
    </Locales>
  );
};

export default AdminPanelProviders;
