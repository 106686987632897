import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "utils/routes";
import productLogo from "images/ic-logo-long-version-white.svg";
import { SearchField } from "components/atoms/SearchField/SearchField";
import { useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import AccountMenu from "components/molecules/Menu/AccountMenu";
import { AddProductButtonMenu } from "components/molecules/Menu/AddProductButtonMenu";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const theme = useTheme();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <Box display="flex" sx={{ px: theme.contentPadding, alignItems: "center", justifyContent: "center" }}>
      <Box>
        <FormControl variant="outlined">
          <SearchField customvariant="SecondaryOutlined" />
        </FormControl>
      </Box>
      <Box sx={{ flexGrow: 0.5 }} />
      <RouterLink to={routes.home}>
        <Box display="flex" sx={{ alignItems: "center", mr: "10px", justifyContent: "center" }}>
          <img src={productLogo} alt="" style={{ width: "240px", height: "30px" }} />
        </Box>
      </RouterLink>
      <Box sx={{ flexGrow: 0.5 }} />
      <Button
        variant="outlined"
        onClick={
          !isAuthenticated
            ? () =>
                loginWithRedirect({
                  appState: {
                    returnTo: routes.adminPitchYourStartup,
                  },
                })
            : () => navigate(routes.adminPitchYourStartup)
        }
        color="secondary"
        sx={{ mr: "10px", "&.Mui-disabled": { color: "grey", borderColor: "grey" } }}
      >
        Pitch your Startup
      </Button>
      {!isAuthenticated ? (
        <>
          <Button variant="contained" color="approved" onClick={() => loginWithRedirect()}>
            Add Startup
          </Button>
        </>
      ) : (
        <>
          <AddProductButtonMenu />
          <AccountMenu />
        </>
      )}
    </Box>
  );
};
