import Box from "@mui/material/Box";
import { Navbar } from "components/molecules/Menu/NavBar";
import CssBaseline from "@mui/material/CssBaseline";
import { mainStyles } from "theme/mainStyles";
import { useTheme } from "@mui/system";
import { Footer } from "components/organisms/Footer/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileMenu from "components/molecules/Menu/MoblieMenu";

export const MainTemplates = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box>
        <CssBaseline />
        {mainStyles}
        {isMobile ? (
          <Box sx={{ py: 1, bgcolor: "background.secondary", color: "text.secondary" }}>
            <MobileMenu />
          </Box>
        ) : (
          <Box sx={{ bgcolor: "background.secondary", color: "text.secondary", display: "flex", justifyContent: "center" }}>
            <Box sx={{ py: 1, width: theme.width }}>
              <Navbar />
            </Box>
          </Box>
        )}
        <Box sx={{ bgcolor: "background.primary", color: "text.primary" }}>
          <Box>{children}</Box>
        </Box>
        <Box sx={{ bgcolor: "background.secondary", color: "text.secondary", display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: theme.width }}>
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
