import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import { useResponsivePadding } from "theme/useResponsivePadding";
import { SubscribeForm } from "components/molecules/SubscribeForm/SubscribeForm";
import { ICLinksIcons } from "components/molecules/ICLinksIcons/ICLinksIcons";
import productLogo from "images/ic-logo-long-version-white.svg";
import Link from "@mui/material/Link";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { trackFooterLinksEngaged } from "utils/segmentAnalytics";

export const Footer = () => {
  const responsivePadding = useResponsivePadding();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  const handleLinkClick = (link) => {
    trackFooterLinksEngaged(link);
  };

  return (
    <Box sx={{ py: 10, px: responsivePadding }}>
      <Box>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <img src={productLogo} alt="" style={{ width: "240px", height: "30px" }} />
              {/* style={{ width: "40px", height: "40px" }} /> */}
            </Box>
            <Typography variant="h1" mb={2}>
              Connect with us
            </Typography>
            <Typography variant="h5" sx={{ mb: 4 }}>
              Discover and connect with tech startups built by Muslims and for Muslims.
            </Typography>
            {/* <Typography display="flex" sx={{ mb: "10px" }}>
              <PhoneIcon sx={{ mr: "10px" }} />
              +1 (437) 000-0000
            </Typography>{" "} */}
            <Typography display="flex" sx={{ mb: 1 }}>
              <EmailIcon sx={{ mr: "10px" }} />
              hello@impactconnect.com
            </Typography>
            <Typography display="flex">
              <LocationOnIcon sx={{ mr: "10px" }} />
              London | Toronto | Istanbul
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} display="flex">
            <Box>
              <SubscribeForm variant="secondary" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={5}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={6}>
            <ICLinksIcons />
          </Grid>

          <Grid item xs={12} sm={6} md={6} display="flex" alignItems="center" justifyContent={isXs ? "flex-start" : "flex-end"}>
            <Link
              component="a"
              onClick={() => handleLinkClick("About Us")}
              href="https://impactconnect.notion.site/Impact-Connect-About-Us-458b456143ac460a8fcae88ed3ca5ff0"
              variant="verySmallText"
              sx={{ textDecoration: "underline", color: "#fff" }}
            >
              About Us
            </Link>
            <Link
              component="a"
              onClick={() => handleLinkClick("Privacy Policy")}
              href="https://impactconnect.notion.site/Impact-Connect-Privacy-Policy-2ed7e01814f8460882838e3f18fc0579"
              variant="verySmallText"
              sx={{ ml: 2, textDecoration: "underline", color: "#fff" }}
            >
              Privacy Policy
            </Link>
            <Link
              component="a"
              onClick={() => handleLinkClick("Terms")}
              href="https://impactconnect.notion.site/Impact-Connect-Terms-and-Conditions-9d77e8695fb741278af004052521beea"
              variant="verySmallText"
              sx={{ ml: 2, textDecoration: "underline", color: "#fff" }}
            >
              Terms of Service
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
