import PropTypes from "prop-types";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";

// third-party
import { useDropzone } from "react-dropzone";

// project-imports
import RejectionFiles from "./RejectionFiles";
import PlaceholderContent from "./PlaceholderContent";

const DropzoneWrapper = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.paper,
  border: "1px dashed",
  borderColor: theme.palette.secondary.main,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ==============================|| UPLOAD - SINGLE FILE ||============================== //

export default function SingleFileUpload({ error, file, setFieldValue, fieldName, sx, helperText, touched, setFieldTouched }) {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: { "image/*": [] },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      setFieldValue(
        fieldName,
        Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) })
      );
    },
    onFileDialogCancel: () => setFieldTouched(),
    onDropOver: () => setFieldTouched(),
  });

  const thumbs = file && [
    <img
      key={file.name}
      alt={file.name}
      src={file.preview}
      style={{
        top: 8,
        left: 8,
        borderRadius: 2,
        position: "absolute",
        width: "calc(100% - 16px)",
        height: "calc(100% - 16px)",
        background: theme.palette.background.paper,
      }}
      onLoad={() => {
        // URL.revokeObjectURL(file.preview);
      }}
    />,
  ];

  const onRemove = () => {
    setFieldValue(fieldName, "");
  };

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && { color: "error.main", borderColor: "error.light", bgcolor: "error.lighter" }),
          ...(file && { padding: "12% 0" }),
        }}
      >
        <input {...getInputProps()} />
        <PlaceholderContent />
        {thumbs}
        {file && (
          <IconButton
            aria-label="close"
            onClick={(event) => {
              event.stopPropagation();
              onRemove();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "error.main",
            }}
          >
            <HighlightOffTwoToneIcon />
          </IconButton>
        )}
      </DropzoneWrapper>

      {error && (
        <FormHelperText error id="standard-weight-helper-text-password-login">
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

SingleFileUpload.propTypes = { error: PropTypes.any, file: PropTypes.any, setFieldValue: PropTypes.any, sx: PropTypes.any };
