import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createProduct, updateProduct } from "reduxUtils/productSlice";
import { useSnackbar } from "notistack";
import FirstSectionProductForm from "admin/sections/organisms/ProductForm/FirstSectionProductForm";
import SecondSectionProductForm from "admin/sections/organisms/ProductForm/SecondSectionProductForm";
import ThirdSectionProductForm from "admin/sections/organisms/ProductForm/ThirdSectionProductForm";
import { SuccessDialog } from "admin/sections/organisms/ProductForm/SuccessDialog";
import { LoadingPage } from "utils/LoadingPage";
import { selectProductLoading } from "reduxUtils/productSlice";
import { actionsOnStartup, trackAddMyStartupForm, trackEditMyStartupForm } from "utils/segmentAnalytics";

import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepContent from "@mui/material/StepContent";
import StepButton from "@mui/material/StepButton";

import MainCard from "admin/components/MainCard";

const validationSchemas = [
  Yup.object({
    product_logo: Yup.mixed()
      .required("Required")
      .test("isImage", "File must be an image.", (value) => {
        return value && value.type.includes("image");
      })
      .test("fileSize", "File too large. Must be smaller than 1MB.", (value) => {
        return value && value.size <= 1 * 1024 * 1024;
      }),
    product_name: Yup.string().required("Required").max(50, "Max 50 characters"),
    startup_one_liner: Yup.string().required("Required").max(100, "Max 100 characters"),
    description: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    product_media_files: Yup.mixed()
      .required("Required")
      .test("maxFiles", "Only three images allowed.", (value) => {
        return value.length > 0 && value.length <= 3;
      })
      .test("isImage", "Files must be images.", (value) => {
        return value.length > 0 && value.every((v) => v.type.includes("image"));
      })
      .test("fileSize", "One or more files are too large. Each must be smaller than 1MB.", (value) => {
        return value.length > 0 && value.every((v) => v.size <= 1 * 1024 * 1024);
      }),
  }),
  Yup.object({
    product_website_link: Yup.string().url("Invalid URL").required("Required"),
    instagram_link: Yup.string()
      .url("Invalid URL")
      .matches("https?://(?:www.)?(?:instagram.com|instagr.am)/", "Please enter a valid Instagram URL"),
    facebook_link: Yup.string().url("Invalid URL").matches("https?://(www.)?(facebook.com|fb.com)/", "Please enter a valid Facebook URL"),
    twitter_link: Yup.string().url("Invalid URL").matches("https?://(www.)?(x.com|twitter.com)/", "Please enter a valid Twitter URL"),
    linkedin_link: Yup.string().url("Invalid URL").matches("https?://([w]+.)?linkedin.com/", "Please enter a valid LinkedIn URL"),
    apple_store_link: Yup.string()
      .url("Invalid URL")
      .matches("https?://(apps|itunes).apple.com/[a-z]{2}/", "Please enter a valid Apple Store URL"),
    google_play_link: Yup.string().url("Invalid URL").matches("https?://play.google.com/store/", "Please enter a valid Google Play URL"),
  }),
  Yup.object().shape(
    {
      first_founder_logo: Yup.mixed().when(["first_founder_name", "first_founder_title"], {
        is: (first_founder_name, first_founder_title) => first_founder_name || first_founder_title,
        then: (schema) =>
          schema
            .required("Required")
            .test("isImage", "File must be an image.", (value) => {
              return value && value.type.includes("image");
            })
            .test("fileSize", "File too large. Must be smaller than 1MB.", (value) => {
              return value && value.size <= 1 * 1024 * 1024;
            }),
        otherwise: (schema) => schema,
      }),

      first_founder_name: Yup.string().when(["first_founder_logo", "first_founder_title"], {
        is: (first_founder_logo, first_founder_title) => first_founder_logo || first_founder_title,
        then: () => Yup.string().required("Required").max(50, "Max 50 characters"),
        otherwise: () => Yup.string().max(50, "Max 50 characters"),
      }),
      first_founder_title: Yup.string().when(["first_founder_logo", "first_founder_name"], {
        is: (first_founder_logo, first_founder_name) => first_founder_logo || first_founder_name,
        then: () => Yup.string().required("Required").max(50, "Max 50 characters"),
        otherwise: () => Yup.string().max(50, "Max 50 characters"),
      }),
      first_founder_linkedin: Yup.string().url("Invalid URL"),
      second_founder_logo: Yup.mixed().when(["second_founder_name", "second_founder_title"], {
        is: (second_founder_name, second_founder_title) => second_founder_name || second_founder_title,
        then: (schema) =>
          schema
            .required("Required")
            .test("isImage", "File must be an image.", (value) => {
              return value && value.type.includes("image");
            })
            .test("fileSize", "File too large. Must be smaller than 1MB.", (value) => {
              return value && value.size <= 1 * 1024 * 1024;
            }),
        otherwise: (schema) => schema,
      }),
      second_founder_name: Yup.string().when(["second_founder_logo", "second_founder_title"], {
        is: (second_founder_logo, second_founder_title) => second_founder_logo || second_founder_title,
        then: () => Yup.string().required("Required").max(50, "Max 50 characters"),
        otherwise: () => Yup.string().max(50, "Max 50 characters"),
      }),
      second_founder_title: Yup.string().when(["second_founder_logo", "second_founder_name"], {
        is: (second_founder_logo, second_founder_name) => second_founder_logo || second_founder_name,
        then: () => Yup.string().required("Required").max(50, "Max 50 characters"),
        otherwise: () => Yup.string().max(50, "Max 50 characters"),
      }),
      second_founder_linkedin: Yup.string().url("Invalid URL"),
      third_founder_logo: Yup.mixed().when(["third_founder_name", "third_founder_title"], {
        is: (third_founder_name, third_founder_title) => third_founder_name || third_founder_title,
        then: (schema) =>
          schema
            .required("Required")
            .test("isImage", "File must be an image.", (value) => {
              return value && value.type.includes("image");
            })
            .test("fileSize", "File too large. Must be smaller than 1MB.", (value) => {
              return value && value.size <= 1 * 1024 * 1024;
            }),
        otherwise: (schema) => schema,
      }),
      third_founder_name: Yup.string().when(["third_founder_logo", "third_founder_title"], {
        is: (third_founder_logo, third_founder_title) => third_founder_logo || third_founder_title,
        then: () => Yup.string().required("Required").max(50, "Max 50 characters"),
        otherwise: () => Yup.string().max(50, "Max 50 characters"),
      }),
      third_founder_title: Yup.string().when(["third_founder_logo", "third_founder_name"], {
        is: (third_founder_logo, third_founder_name) => third_founder_logo || third_founder_name,
        then: () => Yup.string().required("Required").max(50, "Max 50 characters"),
        otherwise: () => Yup.string().max(50, "Max 50 characters"),
      }),
      third_founder_linkedin: Yup.string().url("Invalid URL"),
    },
    [
      ["first_founder_name", "first_founder_title"],
      ["first_founder_logo", "first_founder_title"],
      ["first_founder_logo", "first_founder_name"],
      ["second_founder_name", "second_founder_title"],
      ["second_founder_logo", "second_founder_title"],
      ["second_founder_logo", "second_founder_name"],
      ["third_founder_name", "third_founder_title"],
      ["third_founder_logo", "third_founder_title"],
      ["third_founder_logo", "third_founder_name"],
    ]
  ),
];

const countFounders = (values) => {
  let founderCount = 0;
  if (values.first_founder_name) founderCount++;
  if (values.second_founder_name) founderCount++;
  if (values.third_founder_name) founderCount++;
  return founderCount;
};

const ProductForm = ({ step, setStep, initialValues, productId, productName, isEdit }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProdName, setDialogProdName] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector(selectProductLoading);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeStep = (step) => {
    setStep(step);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubmit = async (values) => {
    const handleProductCreation = async (values) => {
      await dispatch(createProduct(values)).unwrap();
      trackAddMyStartupForm(actionsOnStartup.TEAM_PAGE_COMPLETE, countFounders(values));
      enqueueSnackbar("Product has been created successfully.", { variant: "success" });
    };

    const handleProductUpdate = async (values) => {
      await dispatch(updateProduct({ data: values, id: productId })).unwrap();
      trackEditMyStartupForm(actionsOnStartup.TEAM_PAGE_COMPLETE, productName, countFounders(values));
      enqueueSnackbar("Product has been updated successfully.", { variant: "success" });
    };

    const trackProgress = () => {
      switch (step) {
        case 0:
          if (isEdit) trackEditMyStartupForm(actionsOnStartup.MAIN_INFO_PAGE_COMPLETE, productName);
          else trackAddMyStartupForm(actionsOnStartup.MAIN_INFO_PAGE_COMPLETE);
          break;
        case 1:
          if (isEdit) trackEditMyStartupForm(actionsOnStartup.SOCIAL_MEDIA_PAGE_COMPLETE, productName);
          else trackAddMyStartupForm(actionsOnStartup.SOCIAL_MEDIA_PAGE_COMPLETE);
          break;
        default:
          break;
      }
    };

    if (step === 2) {
      values.product_media_file_title = values.product_name;
      isEdit ? await handleProductUpdate(values) : await handleProductCreation(values);
      setDialogProdName(values.product_name);
      setOpenDialog(true);
      return;
    } else {
      trackProgress();
      handleChangeStep(step + 1);
    }
  };

  useEffect(() => {
    if (isEdit) trackEditMyStartupForm(actionsOnStartup.MAIN_INFO_PAGE_VIEWED, productName);
    else trackAddMyStartupForm(actionsOnStartup.MAIN_INFO_PAGE_VIEWED);
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchemas[step]} onSubmit={handleSubmit}>
        {({ values, setFieldValue, errors, touched, setFieldTouched, validateForm }) => (
          <Form>
            <MainCard
              divider={false}
              sx={{ height: "100%" }}
              contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
            >
              <Stepper activeStep={step} orientation="vertical">
                <Step key={"Main info"}>
                  <StepButton onClick={() => handleChangeStep(0)}>
                    <Typography variant="h4">Main info</Typography>
                  </StepButton>
                  <StepContent>
                    <Box sx={{ px: { xs: 1, sm: 10 }, py: 2 }}>
                      <FirstSectionProductForm
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"Social Media Links"}>
                  <StepButton onClick={() => handleChangeStep(1)}>
                    <Typography variant="h4">Social Media Links</Typography>
                  </StepButton>
                  <StepContent>
                    <Box sx={{ px: { xs: 1, sm: 10 }, py: 2 }}>
                      <SecondSectionProductForm touched={touched} errors={errors} />
                    </Box>
                  </StepContent>
                </Step>
                <Step key={"Team"}>
                  <StepButton onClick={() => handleChangeStep(2)}>
                    <Typography variant="h4">Team</Typography>
                  </StepButton>
                  <StepContent>
                    <Box sx={{ px: { xs: 1, sm: 10 }, py: 2 }}>
                      <ThirdSectionProductForm
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        values={values}
                        validateForm={validateForm}
                      />
                    </Box>
                  </StepContent>
                </Step>
              </Stepper>
              <Box display="flex" mt={3}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleChangeStep(step - 1)}
                  sx={{ display: step === 0 ? "none" : undefined }}
                >
                  Go to previous stage
                </Button>
                <Box flexGrow={1} />
                <Button
                  type="submit"
                  variant="contained"
                  color="approved"
                  // onClick={step === 2 ? undefined : () => handleChangeStep(step + 1)}
                >
                  {step === 2 ? "Save" : "Save and continue"}
                </Button>
              </Box>
            </MainCard>
          </Form>
        )}
      </Formik>
      <SuccessDialog open={openDialog} setOpen={setOpenDialog} prodName={dialogProdName} isEdit={isEdit} />
      <LoadingPage open={isLoading} sx={{ backgroundColor: "" }} />
    </>
  );
};

export default ProductForm;
