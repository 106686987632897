import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import NewProductBox from "admin/sections/molecules/NewProductBox/NewProductBox";
import SuggestedProductBox from "admin/sections/molecules/SuggestedProductBox/SuggestedProductBox";
import { SuggestProductForm } from "admin/sections/molecules/SuggestProductForm/SuggestProductForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSuggestedPublicProducts, selectSuggestedPendingProducts } from "reduxUtils/productSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { trackSuggestStartupPopupInitiated } from "utils/segmentAnalytics";
import { pageNames, trackPageViewed } from "utils/segmentAnalytics";

export default function SuggestStartup() {
  const [suggestFormOpen, setSuggestFormOpen] = useState(false);

  const { user } = useAuth0();
  const suggestedPublicProducts = useSelector((state) => selectSuggestedPublicProducts(state, user.sub));
  const suggestedPendingProducts = useSelector((state) => selectSuggestedPendingProducts(state, user.sub));

  const handleSuggestFormOpen = () => {
    trackSuggestStartupPopupInitiated();
    setSuggestFormOpen(true);
  };

  useEffect(() => {
    trackPageViewed(pageNames.SUGGEST_STARTUP);
  }, []);

  return (
    <Box>
      <SuggestProductForm open={suggestFormOpen} setOpen={setSuggestFormOpen} />
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} md={6} lg={3}>
          <NewProductBox text={"Suggest a Startup"} buttonProps={{ onClick: handleSuggestFormOpen }} />
        </Grid>
      </Grid>

      {suggestedPendingProducts.length > 0 && (
        <>
          <Typography variant="h4" sx={{ my: 4 }}>
            Your suggested startups pending in review
          </Typography>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {suggestedPendingProducts.map((product) => (
              <Grid key={product.id} item xs={12} md={6} lg={3} sx={{}}>
                <SuggestedProductBox product={product} isLive={false} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {suggestedPublicProducts.length > 0 && (
        <>
          <Typography variant="h4" sx={{ my: 4 }}>
            Your suggested live startups
          </Typography>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {suggestedPublicProducts.map((product) => (
              <Grid key={product.id} item xs={12} md={6} lg={3} sx={{}}>
                <SuggestedProductBox product={product} isLive={true} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}
