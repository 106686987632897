import { useEffect } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";

// project-imports
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import HorizontalBar from "./Drawer/HorizontalBar";
import Loader from "admin/components/Loader";
import Breadcrumbs from "admin/components/@extended/Breadcrumbs";
// import AuthGuard from "admin/utils/route-guard/AuthGuard";

import { DRAWER_WIDTH, MenuOrientation } from "admin/config";
import useConfig from "admin/hooks/useConfig";
import { handlerDrawerOpen, useGetMenuMaster } from "admin/api/menu";

// ==============================|| MAIN LAYOUT ||============================== //

export default function MainLayout({ children }) {
  const theme = useTheme();

  const { menuMasterLoading } = useGetMenuMaster();
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      handlerDrawerOpen(!downXL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  if (menuMasterLoading) return <Loader />;

  return (
    // <AuthGuard>
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box component="main" sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, p: { xs: 2, md: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : "inherit", mb: isHorizontal ? 2 : "inherit" }} />
        <Container
          maxWidth={container ? "xl" : false}
          sx={{
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: "relative",
            minHeight: "calc(100vh - 110px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Breadcrumbs />
          {/* <Outlet /> */}
          {children}
          <Footer />
        </Container>
      </Box>
    </Box>
    // </AuthGuard>
  );
}
