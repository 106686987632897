import React from "react";
import { Button } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const MainPageLikeButton = (props) => {
  const { upvote, handleUpvote, isLoading, isBoxHovered, isBoxActive, ...buttonProps } = props;
  const theme = useTheme();

  let color = upvote
    ? theme.palette.approved.main
    : isBoxActive
    ? theme.palette.productBoxBorders.active
    : isBoxHovered
    ? theme.palette.productBoxBorders.hover
    : theme.palette.productBoxBorders.primaryLikeButton;

  let borderColor = color == theme.palette.productBoxBorders.primaryLikeButton ? theme.palette.productBoxBorders.borderLikeButton : color;

  return (
    <Button
      sx={{
        color: color,
        borderColor: borderColor,
        "&.Mui-disabled": {
          color: color,
          borderColor: borderColor,
        },
        "&:hover": {
          color: color,
          borderColor: borderColor,
          backgroundColor: upvote && alpha(color, 0.04),
        },
        // "&:active": {
        //   color: upvote ? theme.palette.approved.main : theme.palette.productBoxBorders.active,
        //   borderColor: upvote ? theme.palette.approved.main : theme.palette.productBoxBorders.active,
        // },
        // ".MuiTouchRipple-child": {
        //   backgroundColor: upvote ? theme.palette.approved.main : theme.palette.productBoxBorders.primary,
        // },
      }}
      disabled={isLoading}
      variant="outlined"
      size="large"
      onClick={handleUpvote}
      {...buttonProps}
    >
      {upvote ? <ThumbUpIcon sx={{ mr: "10px" }} /> : <ThumbUpOutlinedIcon sx={{ mr: "10px" }} />}
      Like
    </Button>
  );
};

export default MainPageLikeButton;
