import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { HeaderProductSegment } from "components/organisms/ProductPage/HeaderProductSegment";
import { MainProductSegment } from "components/organisms/ProductPage/MainProductSegment";
import { SideProductSegment } from "components/organisms/ProductPage/SideProductSegment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams, Navigate } from "react-router-dom";
import { selectAuthorised } from "reduxUtils/globalSlice";
import { createUpvote, selectProductBySlugName, selectUpvoteByProductId } from "reduxUtils/productSlice";
import { useResponsivePadding } from "theme/useResponsivePadding";
import ProductHelmet from "utils/ProductHelmet";
import { routes } from "utils/routes";
import {
  trackPageViewed,
  trackStartupDetailsPageViewed,
  trackStartupPageEngaged,
  trackStartupThumbs,
  pageNames,
} from "utils/segmentAnalytics";
import { ProductPreviewBanner } from "components/molecules/ProductPreviewBanner/ProductPreviewBanner";
import HyvorCommentsSection from "components/molecules/HyvorCommentsSection/HyvorCommentsSection";


export const ProductProfile = () => {
  const params = useParams();
  const slugProductName = params.slugProductName;
  const product = useSelector((state) => selectProductBySlugName(state, slugProductName));
  if (!product) {
    return <Navigate to={"*"} />;
  } else {
    return <PoductView product={product} slugProductName={slugProductName} />;
  }
};

const PoductView = ({ product, slugProductName }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const upvote = useSelector((state) => selectUpvoteByProductId(state, product?.id));
  const responsivePadding = useResponsivePadding();

  const location = useLocation();
  const referrer = location.state?.referrer;

  const isAuthorised = useSelector(selectAuthorised);

  useEffect(() => {
    trackPageViewed(pageNames.STARTUP_PAGE);
  }, []);

  useEffect(() => {
    const likeProduct = localStorage.getItem("likeProduct");
    if (isAuthorised && likeProduct && !upvote) {
      dispatch(createUpvote({ product: likeProduct })).then(() => {
        trackStartupPageEngaged(product, "Like");
        trackStartupThumbs(product, "Like", "Product Page");
      });
      localStorage.removeItem("likeProduct");
    }
    trackStartupDetailsPageViewed(product, referrer === undefined ? "Direct" : referrer);
  }, [slugProductName]);

  return (
    <Box>    
      <ProductHelmet product={product} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: theme.width }}>
          <Box sx={{ py: 10, px: responsivePadding }}>
            {!product.is_public && <ProductPreviewBanner sx={{ mb: 10 }} />}
            {/* breadcrumbs */}
            <Box>
              <Typography variant="h5" display="flex">
                <RouterLink to={routes.home} style={{ color: "inherit", textDecoration: "none" }}>
                  Home
                </RouterLink>
                <Box mx={1}>&gt;</Box>
                <Link underline="none" href="#">
                  {product.product_name}
                </Link>
              </Typography>
            </Box>
            <HeaderProductSegment product={product} upvote={upvote} />
          </Box>
          {/* Product body segment */}
          <Box sx={{ px: responsivePadding, pb: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <MainProductSegment product={product} />
                {product.is_public && (
                  <Box sx={{ mt: 10, display: {sm: 'none', md: 'block'} }}>
                    <HyvorCommentsSection product={product} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <SideProductSegment product={product} />
              </Grid>
              <Grid item xs={12} sm={12} sx={{display: {md: 'none'}}}>
                {product.is_public && (
                  <Box sx={{ mt: 10 }}>
                    <HyvorCommentsSection product={product} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
