import Box from "@mui/material/Box";
import Profile from "./Profile";

export default function MobileSection() {
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Profile />
    </Box>
  );
}
