import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { TextField as FormikTextField } from "formik-mui";

export const StyledTextField = styled(TextField)(({ theme, customvariant }) => ({
  ...(customvariant === "SecondaryFilled" && {
    "& label": {
      color: theme.palette.FieldsBorders.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.FieldsBorders.secondary,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.FieldsBorders.secondary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.FieldsBorders.secondary,
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    "& .MuiInputLabel-shrink": {
      opacity: 0,
      transition: "all 0.2s ease-in",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.text.secondary,
      "& fieldset": {
        borderColor: theme.palette.FieldsBorders.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.FieldsBorders.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.FieldsBorders.secondary,
      },
      "&.Mui-error": {
        "& fieldset": {
          borderColor: theme.palette.error.main,
        },
        "&:hover fieldset": {
          borderColor: theme.palette.error.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.error.main,
        },
      },
    },
  }),
  ...(customvariant === "SecondaryOutlined" && {
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },

    // '& legend': { display: 'none' },
    "& .MuiInputLabel-shrink": {
      opacity: 0,
      transition: "all 0.2s ease-in",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.secondary,
      },
    },
  }),
  ...(customvariant === "Main" && {
    "& label": {
      color: theme.palette.text.primary,
    },
    "& label.Mui-focused": {
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.FieldsBorders.primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.FieldsBorders.primary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.FieldsBorders.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.FieldsBorders.primary,
      },
    },
  }),
}));

export const StyledFormikTextField = styled(FormikTextField)(({ theme, customvariant }) => ({
  ...(customvariant === "SecondaryFilled" && {
    "& label": {
      color: theme.palette.FieldsBorders.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.FieldsBorders.secondary,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.FieldsBorders.secondary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.FieldsBorders.secondary,
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    "& .MuiInputLabel-shrink": {
      opacity: 0,
      transition: "all 0.2s ease-in",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.text.secondary,
      "& fieldset": {
        borderColor: theme.palette.FieldsBorders.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.FieldsBorders.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.FieldsBorders.secondary,
      },
      "&.Mui-error": {
        "& fieldset": {
          borderColor: theme.palette.error.main,
        },
        "&:hover fieldset": {
          borderColor: theme.palette.error.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.error.main,
        },
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: theme.palette.error.main,
    },
  }),
  ...(customvariant === "SecondaryOutlined" && {
    "& label": {
      color: theme.palette.text.secondary,
    },
    "& label.Mui-focused": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.text.secondary,
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },

    // '& legend': { display: 'none' },
    "& .MuiInputLabel-shrink": {
      opacity: 0,
      transition: "all 0.2s ease-in",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.text.secondary,
      },
      "&.Mui-error": {
        "& fieldset": {
          borderColor: theme.palette.error.main, //"#EDA6A6",
        },
        "&:hover fieldset": {
          borderColor: theme.palette.error.main,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.error.main,
        },
      },
    },
  }),
}));
