import EmbeddedCalendarImpactCoffee from "admin/sections/molecules/EmbeddedCalendarImpactCoffee/EmbeddedCalendarImpactCoffee";
import MainCard from "admin/components/MainCard";
import Typography from "@mui/material/Typography";
import { pageNames, trackPageViewed } from "utils/segmentAnalytics";
import { useEffect } from "react";

export default function ImpactCoffee() {
  useEffect(() => {
    trackPageViewed(pageNames.IMPACT_COFFEE);
  }, []);

  return (
    <MainCard
      divider={false}
      sx={{ height: "100%", maxWidth: "650px" }}
      contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
    >
      <Typography variant="h5" mb={3}>
        Turn Your Startup Dream into Reality
      </Typography>
      <Typography variant="body1" mb={3}>
        Are you a visionary founder with a groundbreaking idea? Or perhaps you're already knee-deep in building your MVP? Wherever you are
        in your startup journey, we're here to propel you forward.
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        What We Offer
      </Typography>
      <Typography component="ul" variant="body1" mb={5} style={{ listStylePosition: "outside" }}>
        <li>Idea Validation: Test the viability of your concept before investing significant resources.</li>
        <li>MVP Development: Streamline your product development process and create a compelling minimum viable product.</li>
        <li>Market Strategy: Identify your target audience and develop a go-to-market strategy that resonates.</li>
        <li>Funding Preparation: Get your startup investor-ready with pitch deck reviews and financial modeling.</li>
      </Typography>
      <EmbeddedCalendarImpactCoffee />
      <Typography variant="body1" my={5}>
        Don't let another day pass without taking action. Book a free 30-minute Impact Coffee chat with us and see how we can help your
        startup.
      </Typography>
    </MainCard>
  );
}
