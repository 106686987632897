import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ImageGallery from "react-image-gallery";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const useSlicerHeight = () => {
  const theme = useTheme();
  const screenXl = useMediaQuery("(max-width:1500px)");
  const screenLg = useMediaQuery("(max-width:1200px)");
  const screenLgMd = useMediaQuery("(max-width:1100px)");
  const screenMd = useMediaQuery("(max-width:900px)");
  const screenMdSm = useMediaQuery("(max-width:800px)");
  const screenSm = useMediaQuery("(max-width:600px)");

  const getHeight = () => {
    if (screenSm) return 300;
    if (screenMdSm) return 350;
    if (screenMd) return 450;
    if (screenLgMd) return 350;
    if (screenLg) return 450;
    if (screenXl) return 500;
    return 500;
};

  const [slicerHeight, setSlicerHeight] = useState(500);

  useEffect(() => {
    setSlicerHeight(getHeight());
  }, [screenXl, screenLg, screenLgMd, screenMd, screenMdSm, screenSm]);

  return slicerHeight;
};

export const ImageSlicer = ({ product }) => {
  const [imageDescription, setImageDescription] = React.useState(product.product_media_files[0].title);

  const [showNavButtons, setShowNavButtons] = React.useState(false);
  const slicerHeight = useSlicerHeight();

  return (
    <>
      <Box onMouseOver={() => setShowNavButtons(true)} onMouseLeave={() => setShowNavButtons(false)}>
        <ImageGallery
          items={product.product_media_files.map((media) => {
            return {
              original: media.media_file,
              thumbnail: media.media_file,
              originalHeight: slicerHeight,
              originalWidth: "100%",
              originalTitle: media.title,
              originalClass: "image-gallery-image",
            };
          })}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          onSlide={(index) => setImageDescription(product.product_media_files[index].title)}
          showNav={showNavButtons}
        />
      </Box>
      <Box sx={{ borderLeft: 2, pl: 1, mt: 1 }}>
        <Typography variant="body1">{imageDescription}</Typography>
      </Box>
    </>
  );
};
