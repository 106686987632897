
import React from "react";
import Tooltip from "@mui/material/Tooltip";

const ICBadges = ({ product }) => {
    return (
      <>
        {product.verified_badge.map((badge, index) => (
          <Tooltip key={badge.id} title={badge.name}>
            <img
              key={badge.id}
              src={badge.media_file}
              alt={badge.name}
              style={{ marginLeft: index == 0 ? 0 : 6, width: "30px", height: "30px" }}
            />
          </Tooltip>
        ))}
      </>
    );
  };

export default ICBadges;