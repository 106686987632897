import { Home3 } from "iconsax-react";
import { routes } from "utils/routes";

const navigation = {
  id: "group-navigation",
  title: "Navigation",
  type: "group",
  showBreadcrumb: false,
  children: [
    {
      id: "home",
      title: "Home",
      type: "item",
      url: routes.adminHome,
      icon: Home3,
    },
  ],
};

export default navigation;
