import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";
import headerBG from "images/bg.svg";
import { useResponsivePadding } from "theme/useResponsivePadding";

export const PageTitle = ({ children }) => {
  const theme = useTheme();
  const responsivePadding = useResponsivePadding();
  return (
    <Box
      sx={{
        bgcolor: "background.primary",
        color: "text.primary",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: theme.width,
          px: responsivePadding,
          pt: 15,
          mb: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${headerBG})`,
          backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          // backgroundSize: "contain",
          // flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
