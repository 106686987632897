import { Box1, Stop } from "iconsax-react";
import { routes } from "utils/routes";

const startupDirectory = {
  id: "group-startup-directory",
  title: "Startup Directory",
  type: "group",
  showBreadcrumb: true,
  children: [
    {
      id: "suggest-a-startup",
      title: "Suggest a Startup",
      type: "item",
      url: routes.adminSuggestProduct,
      icon: Box1,
    },
    {
      id: "add-my-startup",
      title: "Add my Startup",
      type: "item",
      link: `${routes.adminAddProduct}/:tab`,
      url: routes.adminAddProductDashboard,
      icon: Stop,
    },
  ],
};

export default startupDirectory;
