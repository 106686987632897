import { Box, Typography, TextField } from "@mui/material";
import { Field } from "formik";

const SecondSectionProductForm = ({ touched, errors }) => {
  return (
    <Box>
      <Typography variant="largeText">Website Link*</Typography>
      <Field
        as={TextField}
        name="product_website_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.product_website_link && Boolean(errors.product_website_link)}
        helperText={touched.product_website_link && errors.product_website_link}
      />
      <Typography variant="largeText">Instagram Link</Typography>
      <Field
        as={TextField}
        name="instagram_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.instagram_link && Boolean(errors.instagram_link)}
        helperText={touched.instagram_link && errors.instagram_link}
      />
      <Typography variant="largeText">Facebook Link</Typography>
      <Field
        as={TextField}
        name="facebook_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.facebook_link && Boolean(errors.facebook_link)}
        helperText={touched.facebook_link && errors.facebook_link}
      />
      <Typography variant="largeText">Twitter Link</Typography>
      <Field
        as={TextField}
        name="twitter_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.twitter_link && Boolean(errors.twitter_link)}
        helperText={touched.twitter_link && errors.twitter_link}
      />
      <Typography variant="largeText">LinkedIn Link</Typography>
      <Field
        as={TextField}
        name="linkedin_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.linkedin_link && Boolean(errors.linkedin_link)}
        helperText={touched.linkedin_link && errors.linkedin_link}
      />
      <Typography variant="largeText">Apple Store Link</Typography>
      <Field
        as={TextField}
        name="apple_store_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.apple_store_link && Boolean(errors.apple_store_link)}
        helperText={touched.apple_store_link && errors.apple_store_link}
      />
      <Typography variant="largeText">Google Play Store Link</Typography>
      <Field
        as={TextField}
        name="google_play_link"
        label=""
        fullWidth
        sx={{ mb: 2 }}
        error={touched.google_play_link && Boolean(errors.google_play_link)}
        helperText={touched.google_play_link && errors.google_play_link}
      />
    </Box>
  );
};

export default SecondSectionProductForm;
