import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthAxios from "utils/AuthAxios";

export const getSponsoredArticle = createAsyncThunk("sponsoredArticle/getSponsoredArticle", async (data) => {
  return AuthAxios.get("sponsored-article/").then((response) => response.data);
});

const initialState = {
  loading: false,
  sponsoredArticle: {},
};

const sponsoredArticleSlice = createSlice({
  name: "sponsoredArticle",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSponsoredArticle.fulfilled, (state, action) => {
        state.loading = false;
        state.sponsoredArticle = action.payload;
      })
      .addCase(getSponsoredArticle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSponsoredArticle.pending, (state, action) => {
        state.loading = true;
      });
  },
});

export const selectSponsoredArticle = (state) => state.sponsoredArticle.sponsoredArticle;

export default sponsoredArticleSlice.reducer;
