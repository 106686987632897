import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// project import
import Avatar from "admin/components/@extended/Avatar";
// import useAuth from "admin/hooks/useAuth";
import { useGetMenuMaster } from "admin/api/menu";

// assets
import { ArrowRight2 } from "iconsax-react";

import avatar1 from "admin/assets/images/users/avatar-1.png";
import avatar from "images/admin/user-icon.png";

const ExpandMore = styled(IconButton, { shouldForwardProp: (prop) => prop !== "theme" && prop !== "expand" && prop !== "drawerOpen" })(
  ({ theme, expand, drawerOpen }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(-90deg)",
    marginLeft: "auto",
    color: theme.palette.secondary.dark,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    ...(!drawerOpen && {
      opacity: 0,
      width: 50,
      height: 50,
    }),
  })
);

// ==============================|| LIST - USER ||============================== //

export default function UserList() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;

  // const { logout, user } = useAuth();
  const handleLogout = async () => {
    // try {
    //   await logout();
    //   navigate(`/login`, {
    //     state: {
    //       from: "",
    //     },
    //   });
    // } catch (err) {
    //   console.error(err);
    // }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ p: 1.25, px: !drawerOpen ? 1.25 : 3, borderTop: "2px solid ", borderTopColor: "divider" }}>
      <List disablePadding>
        <ListItem
          disablePadding
          secondaryAction={
            <ExpandMore
              theme={theme}
              expand={open}
              drawerOpen={drawerOpen}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              aria-label="show more"
            >
              <ArrowRight2 style={{ fontSize: "0.625rem" }} />
            </ExpandMore>
          }
          sx={{
            ...(!drawerOpen && { display: "flex", justifyContent: "flex-end" }),
            "& .MuiListItemSecondaryAction-root": { right: !drawerOpen ? 16 : -16 },
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Avatar" src={avatar} sx={{ ...(drawerOpen && { width: 46, height: 46 }) }} />
          </ListItemAvatar>
          <ListItemText primary={"John Doe"}
          // {user?.name}
           sx={{ ...(!drawerOpen && { display: "none" }) }} secondary="UI/UX Designer" />
        </ListItem>
      </List>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <MenuItem component={Link} to="#" onClick={handleClose}>
          Profile
        </MenuItem>
        <MenuItem component={Link} to="#" onClick={handleClose}>
          My account
        </MenuItem>
      </Menu>
    </Box>
  );
}
