import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ImageSlicer } from "components/molecules/ProductPage/ImageSlicer";
import { FoundingTeamSection } from "components/molecules/ProductPage/FoundingTeamSection";

export const MainProductSegment = ({ product }) => {
  return (
    <Box>
      <Typography variant="h3">What is {product.product_name}?</Typography>
      <Typography mt={2.5} dangerouslySetInnerHTML={{ __html: product.description }} />
      {product.product_media_files.length > 0 && (
        <Box mt={2}>
          <ImageSlicer product={product} />
        </Box>
      )}
      {product.additional_information && <Typography mt={2.5} dangerouslySetInnerHTML={{ __html: product.additional_information }} />}
      <Box mt={5}>
        <FoundingTeamSection product={product} />
      </Box>
      {product.ic_review_content && (
        <Box mt={10}>
          <Typography variant="h3">IC Review</Typography>
          <Box mt={5} borderLeft={2} pl={2}>
            <Box>
              <Typography variant="italicText" dangerouslySetInnerHTML={{ __html: product.ic_review_content }} />
            </Box>
            {product.ic_review_author && (
              <Box mt={2}>
                <Typography variant="italicText">
                  <li>{product.ic_review_author}</li>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
