import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { routes } from "utils/routes";
import { Link as RouterLink } from "react-router-dom";
import AddedProductBox from "admin/sections/molecules/AddedProductBox/AddedProductBox";
import NewProductBox from "admin/sections/molecules/NewProductBox/NewProductBox";
import { useSelector } from "react-redux";
import { selectCreatedPublicProducts, selectCreatedPendingProducts } from "reduxUtils/productSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { pageNames, trackPageViewed } from "utils/segmentAnalytics";
import { useEffect } from "react";

export default function AddStartup() {
  const { user } = useAuth0();
  const createdPublicProducts = useSelector((state) => selectCreatedPublicProducts(state, user.sub));
  const createdPendingProducts = useSelector((state) => selectCreatedPendingProducts(state, user.sub));

  useEffect(() => {
    trackPageViewed(pageNames.ADD_STARTUP);
  }, []);

  return (
    <Box>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} md={6} lg={3} sx={{}}>
          <NewProductBox text={"Add my Startup"} buttonProps={{ component: RouterLink, to: routes.adminAddProductForm }} />
        </Grid>
      </Grid>

      {createdPendingProducts.length > 0 && (
        <>
          <Typography variant="h4" sx={{ my: 4 }}>
            Your startups pending in review
          </Typography>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {createdPendingProducts.map((product) => (
              <Grid key={product.id} item xs={12} md={6} lg={3}>
                <AddedProductBox product={product} isLive={false} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {createdPublicProducts.length > 0 && (
        <>
          <Typography variant="h4" sx={{ my: 4 }}>
            Your live startups
          </Typography>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {createdPublicProducts.map((product) => (
              <Grid key={product.id} item xs={12} md={6} lg={3}>
                <AddedProductBox product={product} isLive={true} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}
