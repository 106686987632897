import React from "react";
import { Box } from "@mui/system";
import { Alert, AlertTitle } from "@mui/material";
import { Typography } from "@mui/material";

export const ProductPreviewBanner = ({ sx }) => {
  return (
    <Box sx={{...sx}}>
      <Alert severity="info" sx={{ p: 2, border: 1, borderColor: "info.main" }}>
        <AlertTitle variant="h5">Note: This is your startup preview.</AlertTitle>
        <Typography>Your startup is currently under review by the Impact Connect team. We'll notify you once the review is complete, and your startup will be immediately published to our directory. </Typography>
      </Alert>
    </Box>
  );
};
