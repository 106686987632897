import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import productLogo from "images/ic-mobile-logo-white.svg";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "utils/routes";
import { SearchField } from "components/atoms/SearchField/SearchField";
import { useAuth0 } from "@auth0/auth0-react";
import AccountMenu from "components/molecules/Menu/AccountMenu";
import { AddProductButtonMenu } from "components/molecules/Menu/AddProductButtonMenu";
import { useNavigate } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export default function MobileMenu({ active }) {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };
  const navigate = useNavigate();

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
    <Box display="flex" sx={{ px: theme.mobileContentPadding, alignItems: "center", justifyContent: "center" }}>
      <IconButton onClick={toggleDrawer(true)} sx={{ color: "text.secondary" }}>
        <MenuIcon />
      </IconButton>
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", mt: 1 }}>
        <RouterLink to={routes.home}>
          <img src={productLogo} alt="" style={{ width: "75px", height: "30px" }} />
        </RouterLink>
      </Box>

      {!isAuthenticated ? (
        <>
          <Button variant="contained" color="approved" onClick={() => loginWithRedirect()}>
            Add Startup
          </Button>
        </>
      ) : (
        <>
          <AddProductButtonMenu />
          <AccountMenu />
        </>
      )}
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box sx={{ mt: 1, mx: 1 }}>
          <FormControl fullWidth variant="outlined">
            <SearchField customvariant={"Main"} onChange={toggleDrawer(false)} />
          </FormControl>
        </Box>
        <List>
          <ListItem key={"pitchYourStartup"} disablePadding>
            <ListItemButton
              onClick={
                !isAuthenticated
                  ? () =>
                      loginWithRedirect({
                        appState: {
                          returnTo: routes.adminPitchYourStartup,
                        },
                      })
                  : () => navigate(routes.adminPitchYourStartup)
              }
            >
              <ListItemText primary={"Pitch your Startup"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
