import { DocumentText1, Coin1 } from "iconsax-react";
import { routes } from "utils/routes";

const investment = {
  id: "group-funding-support",
  title: "Funding & Support",
  type: "group",
  showBreadcrumb: true,
  children: [
    {
      id: "pitch-your-startup",
      title: "Pitch your Startup",
      type: "item",
      url: routes.adminPitchYourStartup,
      icon: Coin1,
      
    },
    {
      id: "impact-coffee",
      title: "Impact Coffee",
      type: "item",
      url: routes.adminImpactCoffee,
      icon: DocumentText1,
    },
  ],
};

export default investment;
