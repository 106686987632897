import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import MainCard from "admin/components/MainCard";

export default function NewProductBox({ text, buttonProps }) {
  const theme = useTheme();
  return (
    <MainCard
      divider={false}
      sx={{ height: "100%", borderStyle: "dashed", border: 2, borderColor: theme.palette.primary[400], backgroundColor: theme.palette.primary.lighter }}
      contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
    >
      <Button
        {...buttonProps}
        sx={{ my: 4, width: "100%" }}
        variant="contained"
        color="approved"
      >
        {text}
      </Button>
    </MainCard>
  );
}
