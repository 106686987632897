import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// material-ui
import ButtonBase from "@mui/material/ButtonBase";

// project-imports
import Logo from "./LogoMain";
import LogoIcon from "./LogoIcon";
// import useAuth from "admin/hooks/useAuth";
import { APP_DEFAULT_PATH } from "admin/config";

import icMobileLogo from "images/ic-mobile-logo.svg";
import icLogo from "images/ic-logo-long-version.svg";

// ==============================|| MAIN LOGO ||============================== //

export default function LogoSection({ isIcon, sx, to }) {
  return (
    // <ButtonBase disableRipple {...(isLoggedIn && { component: Link, to: !to ? APP_DEFAULT_PATH : to, sx })}>
    <ButtonBase disableRipple {...{ component: Link, to: !to ? APP_DEFAULT_PATH : to, sx }}>
      {/* {isIcon ? <LogoIcon /> : <Logo />} */}
      {isIcon ? (
        <img src={icMobileLogo} alt="icon logo" width="50" height="50" />
      ) : (
        <img src={icLogo} alt="icon logo" height="30" />
      )}
    </ButtonBase>
  );
}

LogoSection.propTypes = { isIcon: PropTypes.bool, sx: PropTypes.any, to: PropTypes.any };
