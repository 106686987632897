import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
  width: 1440,
  contentPadding: 8,
  mobileContentPadding: 2,
  main: {
    bgLight: "#fff",
  },
  secondary: {
    bgLight: "#162958",
  },
  tertiary: {
    bgLight: "#E4E9F7",
  },
  palette: {
    background: {
      primary: "#fff",
      secondary: "#162958",
      tertiary: "#E4E9F7",
    },
    text: {
      primary: "#232939",
      secondary: "#fff",
      tertiary: "#162958",
    },
    FieldsBorders: {
      primary: "#D9D9D9",
      secondary: "#505050",
    },
    productBoxBorders: { // for product boxes
      primary: "#E4E9F7",
      hover: "#254493",
      active: "#162958",
      primaryLikeButton: "#96A8D8", // colors for like button in product box
      borderLikeButton: "#C9C9C9",
    },
    boxBorders: { // for arcticles boxes
      primary: "#E4E9F7",
      premium: "#FC732F",
      success: "#89B291",
    },
    primary: {
      main: "#232939",
    },
    secondary: {
      main: "#fff",
      contrastText: "#162958",
    },
    thertiary: {
      main: "#162958",
      contrastText: "#fff",
    },
    approved: {
      main: "#FC732F",
      contrastText: "#fff",
    },
    // secondary: {
    //   main: "#fff",
    // },
    // success: {
    //   main: "#bbf9bb",
    // },
    // light: {
    //   main: "#c0c0c0",
    // },
    // info: {
    //   main: "#181720",
    // },
  },
  typography: {
    fontFamily: "Euclid Circular A, sans-serif",
    htmlFontSize: 10,
    button: {
      textTransform: "none",
    },
    h1: {
      fontFamily: "Clash Display",
      fontSize: "5.6rem",
      fontWeight: 500,
    },
    h2: {
      fontFamily: "Clash Display",
      fontSize: "4.8rem",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Clash Display",
      fontSize: "3.6rem",
      fontWeight: 500,
    },
    h4: {
      fontFamily: "Clash Display",
      fontSize: "2.8rem",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Clash Display",
      fontSize: "2rem",
      fontWeight: 500,
    },
    productH4: {
      fontFamily: "Poppins",
      fontSize: "2.8rem",
      fontWeight: 900,
    },
    productH5: {
      fontFamily: "Poppins",
      fontSize: "2rem",
      fontWeight: 900,
    },

    verySmallText: {
      fontSize: "1.2rem",
      fontWeight: 400,
    },
    smallText: {
      fontSize: "1.4rem",
      fontWeight: 400,
    },
    // mediumText: {
    //   fontSize: "2.0rem",
    //   fontWeight: 500,
    // },
    largeText: {
      fontSize: "2.0rem",
      fontWeight: 400,
    },
    italicText: {
      fontFamily: "Inter",
      fontSize: "1.6rem",
      fontWeight: 400,
      fontStyle: "italic",
    },
  },
  components: {
    MuiTextField: {
      variants: [
        { props: { variant: "SecondaryFilled" }, styles: {} },
        { props: { variant: "SecondaryOutlined" }, styles: {} },
      ],
    },
  },
});
