import { Dialog } from "@mui/material";
import { Box, Typography, TextField } from "@mui/material";
import { Field } from "formik";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AvatarFileUpload from "admin/components/third-party/dropzone/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape(
  {
    founder_logo: Yup.mixed()
      .required("Required")
      .test("isImage", "File must be an image.", (value) => {
        return value && value.type.includes("image");
      })
      .test("fileSize", "File too large. Must be smaller than 1MB.", (value) => {
        return value && value.size <= 1 * 1024 * 1024;
      }),
    founder_name: Yup.string().required("Required").max(50, "Max 50 characters"),
    founder_title: Yup.string().required("Required").max(50, "Max 50 characters"),
    founder_linkedin: Yup.string().url("Invalid URL").matches("https?://([w]+.)?linkedin.com/", "Please enter a valid LinkedIn URL"),
  },
  [
    ["founder_name", "founder_title"],
    ["founder_logo", "founder_title"],
    ["founder_logo", "founder_name"],
  ]
);

export default function AddFounderDialog({ open, setOpen, onSuccess, initialValues, isEdit }) {
  if (!initialValues) {
    initialValues = {
      founder_logo: "",
      founder_name: "",
      founder_title: "",
      founder_linkedin: "",
    };
  }

  const handleSubmit = async (values, { resetForm }) => {
    onSuccess({ ...values, founder_logo: values.founder_logo });
    resetForm();
    setOpen(false);
  };

  const handleClose = (resetForm) => {
    if (isEdit) {
      resetForm();
    }
    setOpen(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => (
        <Dialog open={open} onClose={() => handleClose(resetForm)} fullWidth>
          <Form>
            <Box display="flex" px={3} py={5}>
              <Box maxWidth={480} sx={{ mx: "auto" }}>
                <Typography sx={{ mb: 2 }} variant="h1" align="center">
                  {isEdit ? "Edit a Founder" : "Add a Founder"}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => handleClose(resetForm)}
                  sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <AvatarFileUpload
                  insideText="Founder profile picture"
                  sx={{ mb: 2, display: "flex", flexDirection: "column", alignItems: "center" }}
                  setFieldValue={setFieldValue}
                  fieldName="founder_logo"
                  file={values.founder_logo}
                  error={touched.founder_logo && Boolean(errors.founder_logo)}
                  helperText={touched.founder_logo && errors.founder_logo}
                  setFieldTouched={() => setFieldTouched("founder_logo", true, true)}
                />
                <Typography variant="largeText">Founder Name</Typography>
                <Field
                  as={TextField}
                  name="founder_name"
                  label=""
                  fullWidth
                  sx={{ mb: 2 }}
                  error={touched.founder_name && Boolean(errors.founder_name)}
                  helperText={touched.founder_name && errors.founder_name}
                />
                <Typography variant="largeText">Founder Title</Typography>
                <Field
                  as={TextField}
                  name="founder_title"
                  label=""
                  fullWidth
                  sx={{ mb: 2 }}
                  error={touched.founder_title && Boolean(errors.founder_title)}
                  helperText={touched.founder_title && errors.founder_title}
                />
                <Typography variant="largeText">Founder LinkedIn Link</Typography>
                <Field
                  as={TextField}
                  name="founder_linkedin"
                  label=""
                  fullWidth
                  sx={{ mb: 2 }}
                  error={touched.founder_linkedin && Boolean(errors.founder_linkedin)}
                  helperText={touched.founder_linkedin && errors.founder_linkedin}
                />
                <Button type="submit" variant="contained" fullWidth>
                  Save founder
                </Button>
              </Box>
            </Box>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
