import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import MainCard from "admin/components/MainCard";
import { routes } from "utils/routes";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Edit, ImportSquare } from "iconsax-react";

export default function ProductBox({ product, isLive }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <MainCard
      divider={false}
      sx={{ height: "100%" }}
      contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
    >
      <Box display="flex">
        <Box sx={{ mr: "10px" }}>
          <RouterLink
            style={{ color: "inherit", textDecoration: "none" }}
            to={routes.product.replace(":slugProductName", product.slug_product_name)}
            state={{ referrer: "Direct" }}
          >
            <img
              src={product.product_logo}
              alt=""
              href={routes.product.replace(":slugProductName", product.slug_product_name)}
              style={{ width: "90px", height: "90px" }}
            />
          </RouterLink>
        </Box>

        <Box flexGrow={1}>
          <Box display="flex" flexDirection={isMobile ? "column-reverse" : "row"} justifyContent="space-between">
            <Box display="flex">
              <Typography
                variant={isMobile ? "h4" : "h5"}
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordBreak: "break-all",
                  mr: 0.5,
                }}
              >
                <RouterLink
                  style={{ color: "inherit", textDecoration: "none" }}
                  to={routes.product.replace(":slugProductName", product.slug_product_name)}
                  state={{ referrer: "Direct" }}
                >
                  {product.product_name}
                </RouterLink>
              </Typography>
              <Typography variant={isMobile ? "productH5" : "productH4"}>
                {product.location && getUnicodeFlagIcon(product.location.code)}
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="body2"
          >
            {product.startup_one_liner}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          my: "auto",
          flexWrap: "wrap",
        }}
      >
        {product.categories.map((category, index, array) => {
          return (
            <Box display="inline-flex" key={category.id}>
              <Link
                component="button"
                href="#"
                underline="none"
                sx={{ mr: "10px" }}
                value={category.slug_name}
                variant="body2"
                color="text.primary"
                //   onClick={(e) => setCategory(e.target.value)}
              >
                {"#" + category.name}
              </Link>
              <Typography variant="body2" mr={1}>
                {index === array.length - 1 ? "" : "|"}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ my: "10px" }}>
        <Box sx={{ mt: "10px" }}>
          <Link
            component="button"
            //   underline="none"
            variant="body1"
            color="text.primary"
            //   onClick={(e) => setCategory(e.target.value)}
            display="flex"
            onClick={
              isLive
                ? () => {
                    navigate(routes.product.replace(":slugProductName", product.slug_product_name), { state: { referrer: "Direct" } });
                  }
                : () => {
                    navigate(routes.adminEditProductForm.replace(":slugProductName", product.slug_product_name));
                  }
            }
          >
            {isLive ? <ImportSquare /> : <Edit />}
            <Typography ml={1}>{isLive ? "Visit Startup" : "Edit Startup"}</Typography>
          </Link>
        </Box>
        <Box
          display="flex"
          sx={{
            mt: "auto",
          }}
        >
          <Chip variant="combined" color={isLive ? "success" : "warning"} size="small" label={isLive ? "Live" : "Pending"} />
        </Box>
      </Box>
    </MainCard>
  );
}
