import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthAxios from "utils/AuthAxios";

export const sendMessage = createAsyncThunk("contact/sendMessage", async (data) => {
  return AuthAxios.post("contact/", data).then((response) => response.data);
});

const initialState = {
  isAuthorised: false,
  isContendLoading: true,
  identifyUser: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setAuthorised: (state, action) => {
      state.isAuthorised = action.payload;
    },
    setContentLoading: (state, action) => {
      state.isContendLoading = action.payload;
    },
    setIdentifyUser: (state, action) => {
      state.identifyUser = action.payload;
    },
  },
});

export default globalSlice.reducer;
export const selectAuthorised = (state) => state.global.isAuthorised;
export const selectContentLoading = (state) => state.global.isContendLoading;
export const selectIdentifyUser = (state) => state.global.identifyUser;
export const { setContentLoading, setAuthorised, setLikeProductOnLogin, setIdentifyUser } = globalSlice.actions;
