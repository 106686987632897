import startupDirectory from "./startup-directory";
import navigation from "./navigation";
import fundingSupport from "./funding-support";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [navigation, startupDirectory, fundingSupport],
};

export default menuItems;
