import { Auth0Provider } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setIdentifyUser } from "reduxUtils/globalSlice";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const backend_url = process.env.REACT_APP_BACKEND_URL;

const Auth0ApiProvider = ({ children }) => {
  const dispatch = useDispatch();

  const onRedirectCallback = (appState) => {
    dispatch(setIdentifyUser(true));

    if (appState && appState.returnTo) {
      if (appState.likeProduct) {
        localStorage.setItem("likeProduct", appState.likeProduct);
      }
      window.location.href = `${appState.returnTo}`;
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: backend_url,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ApiProvider;
