import React from "react";
import { Box, Typography, IconButton, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { routes } from "utils/routes";
import { DialogTitle } from "@mui/material";
import confettiBall from "images/admin/startup-submitted-confetti-ball.svg";
import { Divider } from "@mui/material";

export const SuccessDialog = ({ open, setOpen, prodName, isEdit }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(routes.adminAddProductDashboard);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{isEdit ? "Startup edited successfully" : "Startup submitted successfully"}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "text.tertiary",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <Box
        display="flex"
        sx={{
          pt: 4,
          px: { xs: 4, sm: 10 },
          pb: { xs: 4, sm: 10 },
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2.5 }}>
            <Box
              component="img"
              sx={{ height: { xs: "100px", sm: "175px" }, width: { xs: "100px", sm: "175px" } }}
              src={confettiBall}
              alt="confetti ball"
            />
          </Box>
          <Typography sx={{ typography: { xs: "h4", sm: "h2" } }} align="center">
            {isEdit ? <>Your startup, {prodName}, has been updated successfully.</> : <>Startup submitted successfully</>}
          </Typography>
          <Typography sx={{ mt: { xs: 1, sm: 4 }, typography: { xs: "body2", sm: "body0" } }} align="center">
            We're reviewing your startup submission. Our team is checking to make sure it meets our standards and fits our platform.
          </Typography>
          <Typography sx={{ mt: { xs: 1, sm: 4 }, typography: { xs: "body2", sm: "body0" } }} mt={4} align="center">
            We'll let you know as soon as the review is done. If approved, your startup will appear in our directory right away. This means
            investors, mentors, and potential partners will be able to see it.
          </Typography>
          <Typography sx={{ mt: { xs: 1, sm: 4 }, typography: { xs: "body2", sm: "body0" } }} mt={4} align="center">
            Being in our directory will help more people notice your startup and create new opportunities for you.
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
