import PropTypes from "prop-types";
// material-ui
import { alpha, styled, useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import Box from "@mui/material/Box";

// third-party
import { useDropzone } from "react-dropzone";

// project-imports
import RejectionFiles from "./RejectionFiles";

// assets
import { Camera } from "iconsax-react";
import { ProfileAdd } from "iconsax-react";

export const RootWrapper = styled("div")(({ theme }) => ({
  width: 124,
  height: 124,
  borderRadius: "50%",
  border: "1px dashed",
  borderColor: theme.palette.primary.main,
  background: theme.palette.primary.lighter,
}));

export const DropzoneWrapper = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": { cursor: "pointer", "& .placeholder": { zIndex: 9 } },
});

const PlaceholderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  backgroundColor: alpha(theme.palette.primary.lighter, 0.75),
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.85 },
}));

// ==============================|| UPLOAD - AVATAR ||============================== //

export default function AvatarUpload({ error, file, setFieldValue, fieldName, sx, helperText, touched, setFieldTouched, insideText }) {
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: { "image/*": [] },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;
      setFieldValue(
        fieldName,
        Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) })
      );
    },
    onFileDialogCancel: () => setFieldTouched(),
    onDropOver: () => setFieldTouched(),
  });

  const thumbs = file && [
    <img
      key={file.name}
      alt={file.name}
      src={file.preview}
      onLoad={() => {
        // URL.revokeObjectURL(file.preview);
      }}
    />
  ];
  const onRemove = () => {
    setFieldValue(fieldName, "");
  };

  return (
    <Box sx={{ ...sx }}>
      <RootWrapper sx={{ ...((isDragReject || error) && { color: "error.main", borderColor: "error.light", bgcolor: "error.lighter" }) }}>
        <DropzoneWrapper {...getRootProps()} sx={{ ...(isDragActive && { opacity: 0.6 }) }}>
          <input {...getInputProps()} />
          {thumbs && thumbs.length > 0 ? (
            thumbs
          ) : (
            <Stack spacing={0.5} alignItems="center" justifyContent="center">
              <Camera style={{ fontSize: "2rem" }} />
              <Typography align="center" sx={{ align: "right" }}>
                {insideText ? insideText : "Upload"}
              </Typography>
            </Stack>
          )}
          {file && (
            <IconButton
              aria-label="close"
              onClick={(event) => {
                event.stopPropagation();
                onRemove();
              }}
              sx={{
                borderRadius: "50%",
                position: "absolute",
                right: 8,
                top: 8,
                color: "error.main",
                zIndex: 10,
              }}
            >
              <HighlightOffTwoToneIcon />
            </IconButton>
          )}
        </DropzoneWrapper>
      </RootWrapper>
      {error && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <FormHelperText error id="standard-weight-helper-text-password-login">
            {helperText}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
}

AvatarUpload.propTypes = { error: PropTypes.any, file: PropTypes.any, setFieldValue: PropTypes.any, sx: PropTypes.any };
