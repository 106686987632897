import React from "react";
import { Helmet } from "react-helmet-async";
import icSeoImage from "images/ic-seo.jpeg";
export default function MainPageHelmet() {
    return (
        <Helmet prioritizeSeoTags>
            <title>Impact Connect</title>
            {/* <meta name="description" content="A platform for Muslim startups & entrepreneurs to connect, share ideas, receive feedback, and access funding opportunities." />
            <meta property="og:title" content="Discover Innovative Muslim Tech Startups" />
            <meta property="og:description" content="A platform for Muslim startups & entrepreneurs to connect, share ideas, receive feedback, and access funding opportunities." />
            <meta property="og:image" content={icSeoImage} />
            <meta property="og:image:width" content="1080" />
            <meta property="og:image:height" content="1080" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@ImpactConnectX" /> */}
        </Helmet>
    );
}
