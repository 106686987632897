import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MainCard from "admin/components/MainCard";
import AnimateButton from "admin/components/@extended/AnimateButton";
import { MessageFavorite } from "iconsax-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

// ==============================|| DRAWER CONTENT - NAV CARD ||============================== //

const tallyHref = process.env.REACT_APP_TALLY_SHARE_FEEDBACK_FORM_HREF_URL;

export default function NavCard() {
  const { user } = useAuth0();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <MainCard sx={{ bgcolor: "primary.lighter", m: 3 }} contentSX={{ px: 1 }}>
      <Stack alignItems="center" spacing={4}>
        <Stack sx={{ backgroundColor: "primary.main", p: 1.5, borderRadius: 1, lineHeight: 0 }}>
          <MessageFavorite color="#fff" width="100" height="100" variant="Bold" />
        </Stack>
        <Stack alignItems="center">
          <Typography variant="h5">Feedback</Typography>
          <Typography variant="body2" color="secondary" align="center" sx={{ whiteSpace: "normal" }}>
            What could we add to make your experience better?
          </Typography>
        </Stack>
        <AnimateButton>
          <Button
            variant="shadow"
            size="small"
            component={Link}
            href={`${tallyHref}&email=${user.email}`}
            target="_blank"
          >
            Share feedback
          </Button>
        </AnimateButton>
      </Stack>
    </MainCard>
  );
}
