import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { StyledTextField } from "components/atoms/TextField/TextField";
import { ProductBox } from "components/molecules/ProductBox/ProductBox";
import { MenuItem } from "@mui/material";
import { Grid } from "@mui/material";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCategoriesList, selectPublicProductList, selectLocationsList } from "reduxUtils/productSlice";
import { trackStartupDirectoryFiltered } from "utils/segmentAnalytics";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "utils/routes";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const ProductGrid = () => {
  const prodcutPerPage = 18;

  const urlLocation = useLocation();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const slugCategory = params?.get("category");

  const categories = useSelector(selectCategoriesList);
  const locations = useSelector(selectLocationsList);
  const products = useSelector(selectPublicProductList);

  const [filteredProducts, setFilteredProducts] = useState(products);

  const [category, setCategory] = useState(slugCategory && categories.some((c) => c.slug_name === slugCategory) ? slugCategory : "");
  const [location, setLocation] = useState("");
  const [productDisplayCount, setProductDisplayCount] = useState(prodcutPerPage);

  const firstUpdate = useRef(true);

  useEffect(() => {
    const fltProducts = products.filter(
      (product) =>
        (category === "" || product.categories.some((c) => c.slug_name === category)) &&
        (location === "" || product.location.id === location)
    );
    setFilteredProducts(fltProducts);
    setProductDisplayCount(prodcutPerPage);

    if (!firstUpdate.current) {
      trackStartupDirectoryFiltered(
        location !== "" ? locations.find((l) => l.id === location).country_name : "All",
        category !== "" ? categories.find((c) => c.slug_name === category).name : "All",
        fltProducts.length
      );
    }
    firstUpdate.current = false;
  }, [location, category]);

  useEffect(() => { // to fix bug when you redirect to home page from home page when the category is selected
    if(!params?.get("category")){
      setCategory("");
    }
    setLocation("");
  }, [urlLocation]);

  const handleLoadMore = () => {
    setProductDisplayCount(productDisplayCount + prodcutPerPage);
  };

  const handleLoadLess = () => {
    setProductDisplayCount(productDisplayCount - prodcutPerPage);
  };

  useEffect(() => {
    if (category === "") navigate(routes.home, { replace: true });
    else navigate(routes.homeCategory.replace(":slugCategory?", category), { replace: true });
  }, [category]);

  return (
    <Box>
      <Box display="flex">
        <Grid container>
          <Grid item sm={12} md={12} lg={8} sx={{ mb: "10px", display: "flex", alignItems: "center" }}>
            <Typography variant="h2" mr={1}>
              Your next favourite start-up
            </Typography>
            <PanToolAltIcon fontSize="large" style={{ transform: "rotate(180deg)" }} />
          </Grid>
          <Box sx={{ flexGrow: 1 }} />

          <Grid item xs={6} sm={6} md={6} lg={2}>
            <Box sx={{ minWidth: 150, mr: "10px" }}>
              <FormControl fullWidth>
                <StyledTextField
                  id="product-location"
                  value={location}
                  label={location ? "" : "Location"}
                  onChange={(event) => setLocation(event.target.value)}
                  color=""
                  select
                  customvariant="Main"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                    style: { marginLeft: 30 },
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {locations.map((location) => {
                    return (
                      <MenuItem key={location.id} value={location.id}>
                        {location.country_name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={2}>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <StyledTextField
                  id="product-category"
                  value={category}
                  label={category ? "" : "Category"}
                  onChange={(event) => setCategory(event.target.value)}
                  color=""
                  select
                  customvariant="Main"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FolderCopyIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: false,
                    style: { marginLeft: 30 },
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {categories.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.slug_name}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ my: "50px" }}>
        <Grid container spacing={4}>
          {filteredProducts.slice(0, productDisplayCount).map((product) => {
            return (
              <Grid key={product.id} item xs={12} sm={12} md={6} lg={4}>
                <ProductBox product={product} setCategory={setCategory} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box display="flex" sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="approved"
          onClick={handleLoadMore}
          sx={{ display: productDisplayCount >= filteredProducts.length ? "none" : "flex", marginX: 1 }}
        >
          Load More Startups&nbsp;
          <PanToolAltIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />
        </Button>
      </Box>
    </Box>
  );
};
