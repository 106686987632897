import axios from 'axios'

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const backend_url = process.env.REACT_APP_BACKEND_URL;

const AuthAxios = axios.create({
    baseURL: backend_url,
});

AuthAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {

      if (error.response.status === 401) {
        const logoutURL = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(window.location.origin)}`;
          setTimeout(() => {
            window.location.assign(logoutURL);
          }, 2000);
      }

      throw new Error(JSON.stringify(
        {message: error.message, data: error.response.data, code: error.response.status }
      ));
    }
    throw error;
  }
);


export default AuthAxios;