import React from "react";
import IconButton from "@mui/material/IconButton";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { createRedirectUrl } from "utils/helperFunc";
import { trackStartupPageEngaged } from "utils/segmentAnalytics";

export const LinksIcons = ({ product }) => {
  const handleLinkClick = (action) => {
    trackStartupPageEngaged(product, action);
  };

  return (
    <>
      {product.instagram_link && (
        <IconButton
          color={"inherit"}
          size="small"
          onClick={() => handleLinkClick("Instagram")}
          href={createRedirectUrl(product.instagram_link, product.product_name)}
          target="_blank"
        >
          <InstagramIcon />
        </IconButton>
      )}
      {product.linkedin_link && (
        <IconButton
          color={"inherit"}
          size="small"
          onClick={() => handleLinkClick("LinkedIn")}
          href={createRedirectUrl(product.linkedin_link, product.product_name)}
          target="_blank"
        >
          <LinkedInIcon />
        </IconButton>
      )}
      {product.twitter_link && (
        <IconButton
          color={"inherit"}
          size="small"
          onClick={() => handleLinkClick("Twitter")}
          href={createRedirectUrl(product.twitter_link, product.product_name)}
          target="_blank"
        >
          <XIcon />
        </IconButton>
      )}
      {product.facebook_link && (
        <IconButton
          color={"inherit"}
          size="small"
          onClick={() => handleLinkClick("Facebook")}
          href={createRedirectUrl(product.facebook_link, product.product_name)}
          target="_blank"
        >
          <FacebookOutlinedIcon />
        </IconButton>
      )}
    </>
  );
};
