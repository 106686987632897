import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MainCard from "admin/components/MainCard";
import suggestAStartupImage from "images/admin/suggest-a-startup.svg";
import addMyStartupImage from "images/admin/add-my-startup.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "utils/routes";
import { pageNames, trackPageViewed } from "utils/segmentAnalytics";
import { useEffect } from "react";

export default function HomePage() {

  useEffect(() => {
    trackPageViewed(pageNames.PORTAL_HOME);
  }, []);

  const navigation = useNavigate();
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} md={6} sx={{}}>
        <MainCard
          divider={false}
          sx={{ height: "100%" }}
          contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
        >
          <Box>
            <Typography variant="h5">Suggest a Startup</Typography>
            <Typography variant="body2" sx={{ mt: "1rem" }}>
              Help Muslims around the world discover innovative tech startups created by Muslims.
            </Typography>
          </Box>
          <Box>
            <img src={suggestAStartupImage} alt="Suggest a Startup" style={{ width: "100%", marginTop: "1rem" }} />
            <Button
              sx={{ mt: 1, width: "100%" }}
              variant="contained"
              color="approved"
              onClick={() => navigation(routes.adminSuggestProduct)}
            >
              Suggest a Startup
            </Button>
          </Box>
        </MainCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <MainCard
          divider={false}
          sx={{ height: "100%" }}
          contentSX={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
        >
          <Box>
            <Typography variant="h5">Add my Startup</Typography>
            <Typography variant="body2" sx={{ mt: "1rem" }}>
              Help Muslims around the world discover your innovative tech startup and transform the way they engage with technology.
            </Typography>
          </Box>
          <Box>
            <img src={addMyStartupImage} alt="Suggest a Startup" style={{ width: "100%", marginTop: "1rem" }} />
            <Button
              sx={{ mt: 1, width: "100%" }}
              variant="contained"
              color="approved"
              onClick={() => navigation(routes.adminAddProductDashboard)}
            >
              Add my Startup
            </Button>
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
}
