import React from "react";
import { Helmet } from "react-helmet-async";
import icSeoImage from "images/ic-seo.jpeg";

function getTwitterUsername(urlString) {
  let twitterUsername = "";
  try {
    const url = new URL(urlString);
    const pathParts = url.pathname.split("/").filter((part) => part);
    twitterUsername = pathParts[0];
  } catch (error) {
    return "";
  }
  return twitterUsername;

}

function stripHtmlTags(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
}

export default function ProductHelmet({ product }) {
  const twitterUsername = getTwitterUsername(product.twitter_link);
  const descriptionWithoutHtml = stripHtmlTags(product.description);

  return (
    <Helmet prioritizeSeoTags>
      <title>{product.product_name} - Impact Connect</title>
      {/* <meta name="description" content={product.description} />
      <meta property="og:title" content={product.product_name + " - " + product.startup_one_liner} />
      <meta property="og:description" content={product.description} />
      <meta property="og:image" content={product.product_media_files.length > 0 ? product.product_media_files[0].media_file : ""}/>
      <meta property="og:image:width" content={product.product_media_files.length > 0 ? "1280" : ""}/>
      <meta property="og:image:height" content={product.product_media_files.length > 0 ? "720" : ""}/>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterUsername ? "@" + twitterUsername : ""} /> */}
    </Helmet>
  );
}
