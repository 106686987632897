import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Card from "@mui/material/Card";

const tallySrc = process.env.REACT_APP_TALLY_PITCH_YOUR_STARTUP_FORM_URL;

export default function EmbeddedFormPitchYourStartup() {

  const { user } = useAuth0();

  useEffect(() => {
    const widgetScriptSrc = "https://tally.so/widgets/embed.js";

    const load = () => {
      if (typeof window.Tally !== "undefined") {
        window.Tally.loadEmbeds();
        return;
      }

      document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
    };

    if (typeof window.Tally !== "undefined") {
      load();
      return;
    }

    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement("script");
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <Card variant="outlined" sx={{ p: 2 }}>
      <iframe
        data-tally-src={`${tallySrc}&email=${user.email}`}
        loading="lazy"
        width="100%"
        height="177"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Pitch your Startup"
      ></iframe>
    </Card>
  );
}
