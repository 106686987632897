import jsonp from "jsonp";

export const createRedirectUrl = (url, productName) => {
  return (
    url + "?utm_source=ImpactConnect.tech&utm_medium=website&utm_campaign=" + productName.replace(/ /g, "-").toLowerCase() + "-startup-page"
  );
};

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
  return array;
}

export const subscribeToMailchimp = (email, enqueueSnackbar, mailchimpUrl) => {
  const url = mailchimpUrl;

  jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (error, data) => {
    if (error) {
      enqueueSnackbar("Something went wrong. Please try again later.", { variant: "error" });
      return;
    }
    const { result, msg } = data;
    if (result === "success") enqueueSnackbar(msg, { variant: "success" });
    else enqueueSnackbar(msg, { variant: "error" });
  });
};
