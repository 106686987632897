import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MainPageLikeButton from "components/atoms/MainPageLikeButton/MainPageLikeButton";
import ICBadges from "components/molecules/ICBadges/ICBadges";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { selectAuthorised } from "reduxUtils/globalSlice";
import { createUpvote, deleteUpvote, selectProductLoading, selectUpvoteByProductId } from "reduxUtils/productSlice";
import { routes } from "utils/routes";
import { trackStartupThumbs } from "utils/segmentAnalytics";
import { useNavigate } from "react-router-dom";

export const ProductBox = ({ product, setCategory }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const upvote = useSelector((state) => selectUpvoteByProductId(state, product.id));
  const isLoading = useSelector(selectProductLoading);
  const isAuthorised = useSelector(selectAuthorised);
  const auth = useAuth0();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleUpvote = (event) => {
    event.stopPropagation();
    if (isAuthorised) {
      if (upvote) {
        dispatch(deleteUpvote(upvote.id)).unwrap();
        trackStartupThumbs(product, "Unlike", "Main Page");
      } else {
        dispatch(createUpvote({ product: product.id })).unwrap();
        trackStartupThumbs(product, "Like", "Main Page");
      }
    } else {
      auth.loginWithRedirect({
        appState: {
          returnTo: routes.product.replace(":slugProductName", product.slug_product_name),
          likeProduct: product.id,
        },
      });
    }
  };

  const [isBoxHovered, setIsBoxHovered] = useState(false);
  const [isBoxActive, setIsBoxActive] = useState(false);

  return (
    <Box
      sx={{
        border: 2,
        borderRadius: 2,
        px: 2.5,
        pt: 4,
        pb: 2.5,
        borderColor: isBoxActive
          ? theme.palette.productBoxBorders.active
          : isBoxHovered
          ? theme.palette.productBoxBorders.hover
          : theme.palette.productBoxBorders.primary,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={(event) => {
        event.preventDefault();
        navigate(routes.product.replace(":slugProductName", product.slug_product_name), { state: { referrer: "Direct" } });
      }}
      onMouseEnter={() => setIsBoxHovered(true)}
      onMouseLeave={() => {
        setIsBoxHovered(false);
        setIsBoxActive(false);
      }}
      onMouseDown={() => setIsBoxActive(true)}
      onMouseUp={() => setIsBoxActive(false)}
    >
      <Box>
        <Box display="flex">
          <Box sx={{ mr: "10px" }}>
            <RouterLink
              style={{ color: "inherit", textDecoration: "none" }}
              to={routes.product.replace(":slugProductName", product.slug_product_name)}
              state={{ referrer: "Direct" }}
            >
              <img
                src={product.product_logo}
                alt=""
                href={routes.product.replace(":slugProductName", product.slug_product_name)}
                style={{ width: "90px", height: "90px" }}
              />
            </RouterLink>
          </Box>

          <Box flexGrow={1}>
            <Box display="flex" flexDirection={isMobile ? "column-reverse" : "row"} justifyContent="space-between">
              <Box display="flex">
                <Typography
                  variant={isMobile ? "productH5" : "productH4"}
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordBreak: "break-all",
                    mr: 0.5,
                  }}
                >
                  <RouterLink
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={routes.product.replace(":slugProductName", product.slug_product_name)}
                    state={{ referrer: "Direct" }}
                  >
                    {product.product_name}
                  </RouterLink>
                  
                </Typography>
                <Typography variant={isMobile ? "productH5" : "productH4"}>
                  {product.location && (getUnicodeFlagIcon(product.location.code))}
                </Typography>
              </Box>
            </Box>

            <Typography
              sx={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {product.startup_one_liner}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" sx={{ my: "10px", flexWrap: "wrap" }}>
          {product.categories.map((category, index, array) => {
            return (
              <Box display="inline-flex" key={category.id}>
                <Link
                  component="button"
                  href="#"
                  underline="none"
                  sx={{ mr: "10px" }}
                  value={category.slug_name}
                  onClick={(e) => setCategory(e.target.value)}
                >
                  {"#" + category.name}
                </Link>
                <Typography mr={1}>{index === array.length - 1 ? "" : "|"}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 2.5 }}>
        <Box>
          <MainPageLikeButton
            upvote={upvote}
            handleUpvote={(event) => handleUpvote(event)}
            isLoading={isLoading}
            isBoxHovered={isBoxHovered}
            isBoxActive={isBoxActive}
          />
        </Box>
        <Box
          display="flex"
          sx={{
            mt: "auto",
          }}
        >
          <ICBadges product={product} />
        </Box>
      </Box>
    </Box>
  );
};
