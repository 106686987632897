import { Autocomplete, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectPublicProductList } from "reduxUtils/productSlice";
import { StyledTextField } from "components/atoms/TextField/TextField";
import { routes } from "utils/routes";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

export const SearchField = ({ customvariant, onChange = () => {} }) => {
  const navigate = useNavigate();
  const products = useSelector(selectPublicProductList);
  const [productName, setProductName] = useState("");

  const handleSearch = (newValue) => {
    if (newValue !== null) {
      onChange();
      navigate(routes.product.replace(":slugProductName", newValue.slug_product_name), { state: { referrer: "Search box" } });
    }
  };

  return (
    <Autocomplete
      options={products.map((product) => {
        return { label: product.product_name, id: product.id, logo: product.product_logo, slug_product_name: product.slug_product_name };
      })}
      sx={{ width: 250 }}
      freeSolo
      // disableListWrap
      disableClearable
      value={productName}
      onInputChange={(event, newValue) => {
        setProductName(newValue);
      }}
      onChange={(event, newValue) => {
        handleSearch(newValue);
      }}
      forcePopupIcon={false}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img src={option.logo} loading="lazy" width="20" alt="" />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          size="small"
          customvariant={customvariant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {productName && (
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => {
                      setProductName("");
                    }}
                  >
                    <CloseIcon color="secondary" fontSize="small" />
                  </IconButton>
                )}
                <IconButton edge="end" sx={{ pl: 0 }}>
                  <SearchIcon color="secondary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Search Startup"
        />
      )}
      filterOptions={(options, state) => {
        if (state.inputValue.length > 1) {
          return options.filter((item) => String(item.label).toLowerCase().includes(state.inputValue.toLowerCase()));
        }
        return "";
      }}
    />
  );
};
