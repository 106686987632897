import { configureStore } from "@reduxjs/toolkit";
import ProductSlice from "reduxUtils/productSlice";
import GlobalSlice from "reduxUtils/globalSlice";
import UserProfileSlice from "reduxUtils/userProfileSlice";
import SponsoredArticleSlice from "reduxUtils/sponsoredArticleSlice";
import NotificationMiddleware from "utils/NotificationMiddleware";

export default configureStore({
  reducer: {
    userProfile: UserProfileSlice,
    product: ProductSlice,
    global: GlobalSlice,
    sponsoredArticle: SponsoredArticleSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(NotificationMiddleware),
});
