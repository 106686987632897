import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from "@reduxjs/toolkit";
import AuthAxios from "utils/AuthAxios";
import { shuffleArray } from "utils/helperFunc";

export const listProduct = createAsyncThunk("product/listProduct", async () => {
  return AuthAxios.get("product/products/").then((response) => response.data);
});

export const createProduct = createAsyncThunk("product/createProduct", async (data) => {
  return AuthAxios.post("product/products/", data, { headers: { "Content-Type": "multipart/form-data" } }).then(
    (response) => response.data
  );
});

export const updateProduct = createAsyncThunk("product/updateProduct", async ({ data, id }) => {
  return AuthAxios.put(`product/products/${id}/`, data, { headers: { "Content-Type": "multipart/form-data" } }).then(
    (response) => response.data
  );
});

export const deleteProduct = createAsyncThunk("product/deleteProduct", async (id) => {
  return AuthAxios.delete(`product/products/${id}`).then((response) => response.data);
});

export const retrieveProduct = createAsyncThunk("product/retrieveProduct", async (id) => {
  return AuthAxios.get(`product/products/${id}`).then((response) => response.data);
});

// --------------------------------------------------------------------------------------categories

export const listCategory = createAsyncThunk("product/listCategory", async () => {
  return AuthAxios.get("product/categories/").then((response) => response.data);
});

export const listLocation = createAsyncThunk("product/listLocation", async () => {
  return AuthAxios.get("product/locations/").then((response) => response.data);
});

// --------------------------------------------------------------------------------------upvotes

export const listUpvote = createAsyncThunk("product/listUpvote", async () => {
  return AuthAxios.get(`product/upvotes/`).then((response) => response.data);
});

export const createUpvote = createAsyncThunk("product/createUpvote", async (data) => {
  return AuthAxios.post("product/upvotes/", data).then((response) => response.data);
});

export const deleteUpvote = createAsyncThunk("product/deleteUpvote", async (id) => {
  return AuthAxios.delete(`product/upvotes/${id}`).then((response) => response.data);
});

export const productAdapter = createEntityAdapter();
// Slice
export const ProductSlice = createSlice({
  name: "product",
  initialState: productAdapter.getInitialState({
    data: [],
    loading: false,
    error: null,
    categories: [],
    locations: [],
    upvotes: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        productAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        productAdapter.removeOne(state, action.meta.arg);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(retrieveProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(retrieveProduct.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(retrieveProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(listProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listProduct.fulfilled, (state, action) => {
        state.loading = false;
        // console.log(action.payload);
        let products = action.payload;
        if (Array.isArray(action.payload)) products = shuffleArray(action.payload);
        productAdapter.setAll(state, products);
      })
      .addCase(listProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        productAdapter.addOne(state, action.payload);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // --------------------------------------------------categories
      .addCase(listCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(listCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // --------------------------------------------------locations
      .addCase(listLocation.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.locations = action.payload;
      })
      .addCase(listLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // --------------------------------------------------upvotes
      .addCase(listUpvote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listUpvote.fulfilled, (state, action) => {
        state.loading = false;
        state.upvotes = action.payload;
      })
      .addCase(listUpvote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createUpvote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUpvote.fulfilled, (state, action) => {
        state.loading = false;
        // productAdapter.addOne(state, action.payload);
        state.upvotes.push(action.payload);
      })
      .addCase(createUpvote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUpvote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUpvote.fulfilled, (state, action) => {
        state.loading = false;
        // productAdapter.removeOne(state.upvotes, action.meta.arg);
        state.upvotes = state.upvotes.filter((upvote) => upvote.id !== action.meta.arg);
      })
      .addCase(deleteUpvote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// export const selectPoroductList = (state) => state.product.data;
export const selectProductLoading = (state) => state.product.loading;

export const selectCategoriesList = (state) => state.product.categories;
export const selectLocationsList = (state) => state.product.locations;
export const selectUpvotesList = (state) => state.product.upvotes;

export const selectUpvoteByProductId = (state, productId) => {
  const upvotes = selectUpvotesList(state);
  return upvotes.find((upvote) => upvote.product === productId);
};

export const { selectAll: selectProductList, selectById: selectProduct } = productAdapter.getSelectors((state) => state.product);

export const selectPublicProductList = createSelector(selectProductList, (products) =>
  products.filter((product) => product.is_public === true)
);

export const selectProductBySlugName = (state, slugName) => {
  const products = selectProductList(state);
  return products.find((product) => product.slug_product_name === slugName);
};

export const selectProductsNames = (state) => {
  const products = selectProductList(state);
  return products.map((product) => product.name);
};

export const selectSuggestedPublicProducts = createSelector([selectProductList, (state, userId) => userId], (products, userId) =>
  products.filter((product) => product.contributor === userId.replace("|", ".") && product.is_public === true)
);

export const selectSuggestedPendingProducts = createSelector([selectProductList, (state, userId) => userId], (products, userId) =>
  products.filter((product) => product.contributor === userId.replace("|", ".") && product.is_public === false)
);

export const selectCreatedPublicProducts = createSelector([selectProductList, (state, userId) => userId], (products, userId) =>
  products.filter((product) => product.creator === userId.replace("|", ".") && product.is_public === true)
);

export const selectCreatedPendingProducts = createSelector([selectProductList, (state, userId) => userId], (products, userId) =>
  products.filter((product) => product.creator === userId.replace("|", ".") && product.is_public === false)
);

export default ProductSlice.reducer;
