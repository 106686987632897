import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { MainTemplates } from "templates/MainTemplates";
import { mainTheme } from "theme/mainTheme";
import { TawkChatProvider } from "utils/providers/TawkChatProvider";

const tawkPropertyId = process.env.REACT_APP_TAWK_PROPERTY_ID;
const tawkWidgetId = process.env.REACT_APP_TAWK_WIDGET_ID;

const ClientSideProviders = ({ children }) => {
  return (
    <TawkChatProvider tawkPropertyId={tawkPropertyId} tawkWidgetId={tawkWidgetId}>
      <ThemeProvider theme={mainTheme}>
        <MainTemplates>{children}</MainTemplates>
      </ThemeProvider>
    </TawkChatProvider>
  );
};

export default ClientSideProviders;
