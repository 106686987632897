import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "utils/routes";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { selectPublicProductList } from "reduxUtils/productSlice";
import { useState } from "react";
import { ShareIcons } from "components/molecules/ShareIcons/ShareIcons";
import { Article } from "components/molecules/Article/Article";
import { selectSponsoredArticle } from "reduxUtils/sponsoredArticleSlice";

function getRandomSameCategoriesProducts(products, product, count) {
  products = products.filter((p) => p.id !== product.id);

  const categoriesIds = product.categories.map((category) => category.id);
  let altProdcuts = products.map((p) => {
    if (p.categories.map((category) => category.id).some((categoryId) => categoriesIds.includes(categoryId))) return p;
  });

  altProdcuts = altProdcuts.filter((p) => p !== undefined);

  if (count >= altProdcuts.length) return altProdcuts;

  let randomIndex = 0;
  let resault = [];

  for (let i = 0; i < count; i++) {
    randomIndex = Math.floor(Math.random() * altProdcuts.length);
    resault.push(altProdcuts[randomIndex]);
    altProdcuts.splice(randomIndex, 1);
  }

  return resault;
}

export const SideProductSegment = ({ product }) => {
  const theme = useTheme();
  const products = useSelector(selectPublicProductList);
  const sponsoredArticle = useSelector(selectSponsoredArticle);
  const [alternativeProducts, setAlternativeProducts] = useState([]);

  useEffect(() => {
    setAlternativeProducts(getRandomSameCategoriesProducts(products, product, 2));
  }, [product]);

  return (
    <Box>
      <Box>
        {sponsoredArticle.length > 0 &&
          (sponsoredArticle[0].related_category === null ||
            product.categories.some((c) => c.id === sponsoredArticle[0].related_category.id)) && (
            <Box sx={{ mb: 5, border: 1, borderColor: theme.palette.boxBorders.premium }}>
              <Article article={sponsoredArticle[0]} isSponsored={true} productName={product.product_name} />
            </Box>
          )}
        {product.related_article.length > 0 && (
          <Box sx={{ mb: 5, border: 1, borderColor: theme.palette.boxBorders.success }}>
            <Article article={product.related_article[0]} isSponsored={false} productName={product.product_name} />
          </Box>
        )}
        {/* Alternative product section */}
        {alternativeProducts.length > 0 && (
          <Box mb={5}>
            <Typography variant="h5">Alternative startups</Typography>
            {alternativeProducts.map((altProduct, index) => {
              return (
                <Box key={altProduct.id} sx={{ border: 2, borderRadius: 2, px: 2, py: 4, borderColor: theme.tertiary.bgLight, mt: 2 }}>
                  <Box display="flex">
                    <Box sx={{ mr: "10px" }}>
                      <RouterLink
                        style={{ color: "inherit", textDecoration: "none" }}
                        to={routes.product.replace(":slugProductName", altProduct.slug_product_name)}
                        state={{ referrer: "Related Products" }}
                      >
                        <img src={altProduct.product_logo} alt="" style={{ width: "90px", height: "90px" }} />
                      </RouterLink>
                    </Box>

                    <Box>
                      <Box display="flex">
                        <Typography
                          variant="productH4"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          <RouterLink
                            style={{ color: "inherit", textDecoration: "none" }}
                            to={routes.product.replace(":slugProductName", altProduct.slug_product_name)}
                            state={{ referrer: "Related Products" }}
                          >
                            {altProduct.product_name}
                          </RouterLink>
                          {/* {getUnicodeFlagIcon(product.location)} */}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {altProduct.startup_one_liner}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
        {/* share section */}
        <Box>
          <Typography variant="h5">Share this startup</Typography>
          <Box mt={1}>
            <ShareIcons product={product} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
