import React from "react";
import _ from "lodash";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthAxios from "utils/AuthAxios";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthorised, setAuthorised, selectIdentifyUser, setIdentifyUser } from "reduxUtils/globalSlice";
import { LoadingPage } from "utils/LoadingPage";
import { identifyUser } from "utils/segmentAnalytics";

const backend_url = process.env.REACT_APP_BACKEND_URL;

export const AuthProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0();
  const dispatch = useDispatch();
  const isAuthorised = useSelector(selectAuthorised);
  const runIdentifyUser = useSelector(selectIdentifyUser);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: { audience: backend_url },
        });
        if (!_.isEmpty(accessToken)) {
          AuthAxios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          dispatch(setAuthorised(true));
          if (runIdentifyUser) {
            dispatch(setIdentifyUser(false));
            identifyUser(user);
          }
        }
      }
    })();
  }, [isAuthenticated, isAuthorised]);

  return <>{isLoading || (isAuthenticated && !isAuthorised) ? <LoadingPage open={true} /> : children}</>;
};
