import React from "react";
import { SnackbarProvider, MaterialDesignContent, closeSnackbar } from "notistack";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

export const NotificationProvider = ({ children }) => {
  const theme = useTheme();

  const StyledProvider = styled(SnackbarProvider)(() => ({
    "& #notistack-snackbar": {
      fontSize: "14px !important",
    },
  }));

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.success.main,
      color: "white",
      "& .MuiButton-root": {
        color: "white",
        fontSize: "14px !important",
      },
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
      "& .MuiButton-root": {
        color: "#fff",
        fontSize: "14px !important",
      },
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
      "& .MuiButton-root": {
        color: "#fff",
        fontSize: "14px !important",
      },
    },
  }));

  return (
    <StyledProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      autoHideDuration={3000}
      preventDuplicate={true}
      action={(snackbarId, message, options) => (
        <Button onClick={() => closeSnackbar(snackbarId)} color={options}>
          Dismiss
        </Button>
      )}
    >
      {children}
    </StyledProvider>
  );
};
